.dhx_cal_container #room_filter:focus{
	outline: 1px solid #52daff;
}

.timeline-cell-inner {
	height: 100%;
	width: 100%;
	table-layout: fixed;
}

.timeline-cell-inner td {
	border-left: 1px solid #cecece;
}

.dhx_section_time select {
	display: none;
}

.timeline_weekend {
	background-color: #FFF9C4;
}

.timeline_item_cell {
	float: left;
	width: 32%;
	height: 100% !important;
	font-size: 14px;
	text-align: center;
	line-height: 50px;
}

.room_status {
	position: relative;
}

.timeline_item_separator {
	float: left;
	background-color: #CECECE;
	width: 1px;
	height: 100% !important;
}

.room_status_indicator {
	position: absolute;
	background-color: red;
	left: 0;
	top: 0;
	right: 95%;
	bottom: 0;
}

.room_status_indicator_1 {
	background-color: #4CAF50;
}

.room_status_indicator_2 {
	background-color: red;
}

.room_status_indicator_3 {
	background-color: #FFA000;
}

.dhx_cal_event_line {
	background-color: #FFB74D !important;
}

.event_1 {
	background-color: #FFB74D !important;
}

.event_2 {
	background-color: #9CCC65 !important;
}

.event_3 {
	background-color: #40C4FF !important;
}

.event_4 {
	background-color: #BDBDBD !important;
}

.booking_status, .booking_paid {
	position: absolute;
	right: 5px;
}

.booking_status {
	top: 2px;
}

.booking_paid {
	bottom: 2px;
}

.dhx_cal_event_line:hover .booking-option {
	background: none !important;
}

.dhx_cal_header .dhx_scale_bar {
	line-height: 26px;
	color: black;
}

.dhx_section_time select {
	display: none
}

.dhx_mini_calendar .dhx_year_week,
.dhx_mini_calendar .dhx_scale_bar {
	height: 30px !important;
}

.dhx_cal_light_wide .dhx_section_time {
	text-align: left;
}

.dhx_cal_light_wide .dhx_section_time > input:first-child {
	margin-left: 10px;
}

.dhx_cal_light_wide .dhx_section_time input {
	border: 1px solid #aeaeae;
	padding-left: 5px;
}

.dhx_cal_light_wide .dhx_readonly {
	padding: 3px;
}

.collection_label .timeline_item_cell {
	line-height: 60px;
}

.dhx_cal_radio label, .dhx_cal_radio input {
	vertical-align: middle;
}

.dhx_cal_radio input {
	margin-left: 10px;
	margin-right: 2px;
}

.dhx_cal_radio input:first-child {
	margin-left: 5px;
}

.dhx_cal_radio {
	line-height: 19px;
}

.dhtmlXTooltip.tooltip {
	color: #4d4d4d;
	font-size: 15px;
	line-height: 140%;
}
/*
@license
dhtmlxScheduler v.4.4.4 Professional

This software can be used only as part of dhtmlx.com site.
You are not allowed to use it on any other site

(c) Dinamenta, UAB.

*/

.dhtmlx_message_area {
    position: fixed;
    right: 5px;
    width: 250px;
    z-index: 11
}

.dhtmlx-info {
    min-width: 120px;
    font-family: Tahoma;
    z-index: 14;
    overflow: hidden;
    margin: 5px 5px 10px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease
}

.dhtmlx-info.hidden {
    height: 0;
    padding: 0;
    border-width: 0;
    margin: 0;
    overflow: hidden
}

.dhtmlx_modal_box {
    overflow: hidden;
    display: inline-block;
    min-width: 300px;
    text-align: center;
    position: fixed;
    box-shadow: 0 0 14px #888;
    z-index: 18
}

.dhtmlx_popup_title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-width: 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAoCAMAAAAIaGBFAAAAhFBMVEVwcHBubm5sbGxqampoaGhmZmZlZWVjY2NhYWFfX19dXV1bW1taWlpYWFhWVlZUVFRSUlJRUVFPT09NTU1LS0tJSUlHR0dGRkZERERCQkJAQEA+Pj49PT09PT0+Pj5AQEBBQUFDQ0NERERGRkZHR0dJSUlKSkpMTExMTEw5OTk5OTk5OTkny8YEAAAAQklEQVQImQXBCRJCAAAAwKVSQqdyjSPXNP7/QLsIhA6OTiJnF7GrRCpzc/fw9PKW+/gqlCq1RqvTG/yMJrPF6m/bAVEhAxxnHG0oAAAAAElFTkSuQmCC);
    background: -webkit-linear-gradient(top, #707070 1%, #3d3d3d 70%, #4c4c4c 97%, #393939 97%);
    background: -moz-linear-gradient(top, #707070 1%, #3d3d3d 70%, #4c4c4c 97%, #393939 97%)
}

.dhtmlx-info,
.dhtmlx_button,
.dhtmlx_popup_button {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    cursor: pointer
}

.dhtmlx_popup_text {
    overflow: hidden
}

.dhtmlx_popup_controls {
    border-radius: 6px;
    padding: 5px
}

.dhtmlx_button,
.dhtmlx_popup_button {
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin: 0 5px;
    border-radius: 6px;
    color: #FFF
}

.dhtmlx_popup_button {
    min-width: 120px
}

div.dhx_modal_cover {
    background-color: #000;
    cursor: default;
    filter: alpha(opacity=20);
    opacity: .2;
    position: fixed;
    z-index: 17;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    zoom: 1
}

.dhtmlx-info img,
.dhtmlx_modal_box img {
    float: left;
    margin-right: 20px
}

.dhtmlx-alert-error .dhtmlx_popup_title,
.dhtmlx-confirm-error .dhtmlx_popup_title {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAsCAIAAAArRUU2AAAATklEQVR4nIWLuw2AMBBDjVuQiBT2oWbRDATrnB0KQOJoqPzRe3BrHI6dcBASYREKovtK6/6DsDOX+stN+3H1YX9ciRgnYq5EWYhS2dftBIuLT4JyIrPCAAAAAElFTkSuQmCC)
}

.dhtmlx-alert-error,
.dhtmlx-confirm-error {
    border: 1px solid red
}

.dhtmlx_button,
.dhtmlx_popup_button {
    box-shadow: 0 0 4px #888;
    border: 1px solid #838383
}

.dhtmlx_button input,
.dhtmlx_popup_button div {
    border: 1px solid #FFF;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAeCAMAAADaS4T1AAAAYFBMVEVwcHBtbW1ra2toaGhmZmZjY2NhYWFeXl5cXFxaWlpXV1dVVVVSUlJQUFBNTU1LS0tJSUlGRkZERERBQUE/Pz88PDw9PT0+Pj5AQEBCQkJDQ0NFRUVHR0dISEhKSkpMTEzqthaMAAAAMklEQVQImQXBhQ2AMAAAsOIMlwWH/8+kRSKVyRVKlVrQaHV6g9FktlhFm93hdLk9Xt8PIfgBvdUqyskAAAAASUVORK5CYII=);
    background: -webkit-linear-gradient(top, #707070 1%, #3d3d3d 70%, #4c4c4c 99%);
    background: -moz-linear-gradient(top, #707070 1%, #3d3d3d 70%, #4c4c4c 99%);
    border-radius: 6px;
    font-size: 15px;
    font-weight: 400;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    color: #fff;
    padding: 0;
    margin: 0;
    vertical-align: top;
    height: 28px;
    line-height: 28px
}

.dhtmlx_button input:active,
.dhtmlx_button input:focus,
.dhtmlx_popup_button div:active,
.dhtmlx_popup_button div:focus {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAeCAMAAADaS4T1AAAAXVBMVEVwcHBubm5tbW1sbGxra2tpaWloaGhnZ2dmZmZlZWVjY2NiYmJhYWFgYGBfX19dXV1cXFxbW1taWlpZWVlXV1dWVlZVVVVUVFRTU1NRUVFQUFBPT09OTk5NTU1LS0tT9SY0AAAAMUlEQVQImQXBhQGAMAAAIGxnx2z9/00BiVQmVyhVakGj1ekNRpPZYhVtdofT5fZ4fT8hpwG05JjexgAAAABJRU5ErkJggg==);
    background: -webkit-linear-gradient(top, #707070 1%, #4c4c4c 99%);
    background: -moz-linear-gradient(top, #707070 1%, #4c4c4c 99%)
}

.dhtmlx_popup_title {
    color: #fff;
    text-shadow: 1px 1px #000;
    height: 40px;
    line-height: 40px;
    font-size: 20px
}

.dhtmlx_popup_text {
    margin: 15px 15px 5px;
    color: #000;
    min-height: 30px;
    border-radius: 6px
}

.dhtmlx-error,
.dhtmlx-info {
    font-size: 14px;
    color: #000;
    box-shadow: 0 0 10px #888;
    padding: 0;
    background-color: #FFF;
    border-radius: 3px;
    border: 1px solid #fff
}

.dhtmlx-info div {
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #B8B8B8
}

.dhtmlx-error {
    background-color: #d81b1b;
    border: 1px solid #ff3c3c;
    box-shadow: 0 0 10px #000
}

.dhtmlx-error div {
    background-color: #d81b1b;
    border: 1px solid #940000;
    color: #FFF
}

.dhx_cal_event .dhx_body,
.dhx_cal_event .dhx_footer,
.dhx_cal_event .dhx_header,
.dhx_cal_event .dhx_title {
    background-color: #FFE763;
    border: 1px solid #B7A543;
    color: #887A2E;
    overflow: hidden;
    width: 100%;
    font-family: Tahoma;
    font-size: 8pt
}

.dhx_move_denied .dhx_cal_event .dhx_header,
.dhx_move_denied .dhx_cal_event .dhx_title {
    cursor: default
}

.dhx_cal_event .dhx_header {
    height: 1px;
    margin-left: 1px;
    border-width: 1px 1px 0;
    cursor: pointer
}

.dhx_cal_event .dhx_title {
    border-bottom-style: dotted;
    text-align: center;
    background-position: right;
    background-repeat: no-repeat;
    cursor: pointer
}

.dhx_cal_event .dhx_body,
.dhx_cal_event.dhx_cal_select_menu .dhx_body {
    border-width: 0 1px 1px;
    padding: 5px
}

.dhx_resize_denied,
.dhx_resize_denied .dhx_event_resize {
    cursor: default!important
}

.dhx_cal_event .dhx_event_resize {
    cursor: s-resize
}

.dhx_cal_event .dhx_footer,
.dhx_cal_event .dhx_select_menu_footer {
    height: 1px;
    margin-left: 2px;
    border-width: 0 1px 1px;
    position: relative
}

.dhx_cal_event_line {
    padding-left: 10px;
    cursor: pointer;
    overflow: hidden
}

.dhx_cal_event_clear {
    font-family: Tahoma;
    font-size: 8pt;
    height: 13px;
    padding-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer
}

.dhx_in_move {
    background-color: #FFFF80
}

.dhx_cal_event.dhx_cal_editor {
    z-index: 10;
    position: absolute;
    overflow: hidden
}

textarea.dhx_cal_editor {
    width: 100%;
    height: 100%;
    border: 0 solid #000;
    margin: 0;
    padding: 0;
    overflow: auto
}

div.dhx_menu_head {
    background-position: 0 -43px;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    margin-top: 1px;
    border: none;
    cursor: default
}

div.dhx_menu_icon {
    width: 20px;
    height: 20px;
    margin-left: -5px;
    margin-top: 0;
    border: none;
    cursor: pointer
}

div.icon_details {
    background-position: 0 0
}

div.icon_edit {
    background-position: -22px 0
}

div.icon_save {
    background-position: -84px -1px
}

div.icon_cancel {
    background-position: -62px 0
}

div.icon_delete {
    background-position: -42px 0
}

.dhx_month_link {
    position: absolute;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    text-align: right;
    cursor: pointer;
    padding-right: 10px
}

.dhx_month_link a {
    color: #00f
}

.dhx_month_link a:hover {
    text-decoration: underline
}

.dhx_global_tip {
    font-family: Tahoma, Helvetica;
    text-align: center;
    font-size: 20px;
    position: fixed;
    top: 60px;
    right: 20px;
    background-color: rgba(255, 255, 255, .7);
    color: #000;
    z-index: 14;
    padding: 20px 30px;
    width: 190px
}

.dhx_global_tip div {
    font-size: 30px
}

@media (-moz-touch-enabled) {
    .dhx_cal_container {
        user-select: none;
        -moz-user-select: none
    }
}

.dhx_unselectable,
.dhx_unselectable div {
    -webkit-user-select: none;
    -moz-user-select: none;
    -moz-user-select: -moz-none
}

.dhx_cal_light {
    -webkit-tap-highlight-color: transparent;
    font-size: 8pt;
    position: absolute;
    z-index: 15;
    width: 580px;
    height: 300px;
    box-shadow: 5px 5px 5px #888
}

.dhx_mark {
    position: relative;
    top: 3px;
    background-image: url(imgs/controls.gif);
    background-position: 0 -43px;
    padding-left: 10px
}

.dhx_ie6 .dhx_mark {
    background-position: 6px -41px
}

.dhx_cal_light select {
    font-family: Tahoma;
    font-size: 8pt;
    padding: 2px;
    margin: 0
}

.dhx_cal_ltitle {
    padding: 2px 0 2px 5px;
    overflow: hidden;
    white-space: nowrap
}

.dhx_cal_ltitle span {
    white-space: nowrap
}

.dhx_cal_lsection {
    font-weight: 700;
    padding: 5px 0 3px 10px
}

.dhx_section_time {
    white-space: nowrap
}

.dhx_cal_lsection .dhx_fullday {
    float: right;
    margin-right: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    vertical-align: top;
    cursor: pointer
}

.dhx_cal_lsection {
    font-size: 18px
}

.dhx_cal_ltext {
    padding: 2px 0 2px 10px;
    overflow: hidden
}

.dhx_cal_ltext textarea {
    overflow: auto;
    height: 100%;
    width: 100%;
    outline: 0!important;
    resize: none
}

.dhx_time {
    font-weight: 700
}

.dhx_cal_larea {
    overflow: hidden;
    width: 572px;
    height: 1px
}

.dhx_btn_set {
    float: left
}

.dhx_btn_set div {
    float: left;
    vertical-align: middle;
    cursor: pointer
}

.dhx_save_btn {
    background-image: url(imgs/controls.gif);
    background-position: -84px 0;
    width: 21px
}

.dhx_cancel_btn {
    background-image: url(imgs/controls.gif);
    background-position: -63px 0;
    width: 20px
}

.dhx_delete_btn {
    background-image: url(imgs/controls.gif);
    background-position: -42px 0;
    width: 20px
}

.dhx_cal_cover {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 14;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: .1;
    filter: alpha(opacity=10)
}

.dhx_custom_button {
    padding: 0 3px;
    font-family: Tahoma;
    font-size: 8pt;
    font-weight: 400;
    margin-right: 5px;
    margin-top: 0;
    cursor: pointer
}

.dhx_custom_button div {
    cursor: pointer;
    float: left;
    height: 21px;
    line-height: 21px;
    vertical-align: middle
}

.dhx_cal_light_wide .dhx_cal_larea {
    border-top-width: 0
}

.dhx_cal_light_wide .dhx_cal_lsection {
    border: 0;
    float: left;
    text-align: right;
    width: 100px;
    height: 20px;
    padding: 5px 0 0 10px
}

.dhx_cal_light_wide .dhx_wrap_section {
    position: relative;
    overflow: hidden
}

.dhx_cal_light_wide .dhx_section_time {
    padding-top: 2px!important;
    height: 20px!important
}

.dhx_section_time {
    text-align: center
}

.dhx_cal_light_wide .dhx_cal_larea {
    width: 730px
}

.dhx_cal_light_wide {
    width: 738px
}

.dhx_cal_light_wide .dhx_section_time {
    background: 0 0
}

.dhx_cal_light_wide .dhx_cal_checkbox label {
    padding-left: 0
}

.dhx_cal_wide_checkbox input {
    margin-top: 8px;
    margin-left: 14px
}

.dhx_cal_light input {
    font-size: 8pt
}

.dhx_cal_light_wide .dhx_cal_lsection .dhx_fullday {
    float: none;
    margin-right: 0;
    cursor: pointer
}

.dhx_custom_button {
    float: right;
    height: 21px
}

.dhx_cal_light_wide .dhx_custom_button {
    position: absolute;
    top: 0;
    right: 0
}

.dhx_cal_light_wide .dhx_repeat_right {
    margin-right: 55px
}

.dhx_minical_popup {
    position: absolute;
    z-index: 16;
    width: 251px;
    height: 175px
}

.dhx_scale_bar_header {
    position: absolute;
    border-bottom: 1px dotted #8894A3;
    width: 100%
}

.dhx_expand_icon {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-position: 0 18px;
    z-index: 4
}

.dhx_agenda_area {
    width: 100%;
    overflow-y: auto
}

.dhx_agenda_line {
    clear: both;
    overflow: hidden
}

.dhx_agenda_line div {
    float: left;
    width: 188px;
    text-align: center;
    overflow: hidden
}

.dhx_agenda_area .dhx_agenda_line div {
    border-right: 0 dotted #8894A3
}

.dhx_v_border {
    position: absolute;
    left: 187px;
    top: 0;
    width: 1px;
    height: 100%
}

.dhx_agenda_line .dhx_event_icon {
    width: 20px;
    border-width: 0;
    background: url(imgs/icon.png) 5px 4px no-repeat;
    cursor: pointer
}

.dhx_agenda_line span {
    padding-left: 5px
}

.dhx_year_week {
    position: relative
}

.dhx_year_month {
    height: 18px;
    padding-top: 3px;
    text-align: center;
    vertical-align: middle
}

.dhx_year_body .dhx_after .dhx_month_head,
.dhx_year_body .dhx_after .dhx_month_head a,
.dhx_year_body .dhx_before .dhx_month_head,
.dhx_year_body .dhx_before .dhx_month_head a {
    color: #E2E3E6!important
}

.dhx_year_body .dhx_month_body {
    height: 0;
    overflow: hidden
}

.dhx_month_head.dhx_year_event {
    background-color: #FFE763
}

.dhx_year_body .dhx_after .dhx_month_head,
.dhx_year_body .dhx_before .dhx_month_head {
    cursor: default
}

.dhx_year_tooltip {
    border: 1px solid #BBB;
    background-image: url(imgs/databg.png);
    position: absolute;
    z-index: 12;
    width: 300px;
    height: auto;
    font-family: Tahoma;
    font-size: 8pt;
    overflow: hidden
}

.dhx_tooltip_line {
    line-height: 20px;
    height: 20px;
    overflow: hidden
}

.dhx_tooltip_line .dhx_event_icon {
    width: 20px;
    height: 20px;
    padding-right: 10px;
    float: left;
    border-width: 0;
    position: relative;
    background: url(imgs/icon.png) 5px 4px no-repeat;
    cursor: pointer
}

.dhx_tooltip_date {
    float: left;
    width: auto;
    padding-left: 5px;
    text-align: center
}

.dhx_text_disabled {
    font-family: Tahoma;
    font-size: 8pt
}

.dhx_mini_calendar {
    -moz-box-shadow: 5px 5px 5px #888;
    -khtml-box-shadow: 5px 5px 5px #888;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -user-select: none
}

.dhx_mini_calendar .dhx_month_head {
    cursor: pointer
}

.dhx_mini_calendar .dhx_calendar_click {
    background-color: #C2D5FC
}

.dhx_cal_navline div.dhx_minical_icon {
    cursor: pointer;
    background-image: url(imgs/calendar.gif)
}

.dhx_matrix_scell {
    height: 100%
}

.dhx_matrix_cell,
.dhx_matrix_scell {
    overflow: hidden;
    text-align: center;
    vertical-align: middle
}

.dhx_matrix_cell {
    background-color: #fff
}

.dhx_matrix_line {
    overflow: hidden
}

.dhx_matrix_cell div,
.dhx_matrix_scell div {
    overflow: hidden;
    text-align: center;
    height: auto
}

.dhx_cal_lsection .dhx_readonly {
    font-size: 9pt;
    font-size: 8pt;
    padding: 2px;
    color: #887A2E
}

.dhx_cal_event_line .dhx_event_resize {
    cursor: w-resize;
    position: absolute;
    top: 0;
    width: 4px;
    height: 100%
}

.dhx_event_resize_start {
    left: 0
}

.dhx_event_resize_end {
    right: 0
}

.dhx_data_table.folder .dhx_matrix_cell,
.dhx_matrix_scell.folder {
    background-color: #969394;
    cursor: pointer
}

.dhx_matrix_scell .dhx_scell_level0 {
    padding-left: 5px
}

.dhx_matrix_scell .dhx_scell_level1 {
    padding-left: 20px
}

.dhx_matrix_scell .dhx_scell_level2 {
    padding-left: 35px
}

.dhx_matrix_scell .dhx_scell_level3 {
    padding-left: 50px
}

.dhx_matrix_scell .dhx_scell_level4 {
    padding-left: 65px
}

.dhx_matrix_scell.folder {
    font-weight: 700;
    text-align: left
}

.dhx_matrix_scell.folder .dhx_scell_expand {
    float: left;
    width: 10px;
    padding-right: 3px
}

.dhx_matrix_scell.folder .dhx_scell_name {
    float: left;
    width: auto
}

.dhx_matrix_scell.item .dhx_scell_name {
    padding-left: 15px;
    text-align: left
}

.dhx_data_table.folder .dhx_matrix_cell {
    border-right: 0
}

.dhx_section_timeline {
    overflow: hidden;
    padding: 4px 0 2px 10px
}

.dhx_section_timeline select {
    width: 552px
}

.dhx_map_area {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden
}

.dhx_map_line .dhx_event_icon {
    width: 20px;
    border-width: 0;
    background: url(imgs/icon.png) 5px 4px no-repeat;
    cursor: pointer
}

.dhx_map_line {
    clear: both;
    overflow: hidden
}

.dhx_map {
    position: absolute
}

.dhx_map_line .headline_description,
.dhx_map_line div {
    float: left;
    border-right: 1px dotted #8894A3;
    text-align: center;
    line-height: 21px;
    overflow: hidden
}

.dhx_map_line .dhx_map_description {
    float: left;
    border-right: 0 dotted #8894A3;
    text-align: center;
    line-height: 21px;
    overflow: hidden
}

.dhx_map_line .line_description {
    float: left;
    border-right: 1px dotted #8894A3;
    text-align: left;
    padding-left: 5px;
    overflow: hidden
}

.dhx_map_area .dhx_map_line div {
    border-right: 0 dotted #8894A3
}

.dhtmlXTooltip.tooltip {
    -moz-box-shadow: 3px 3px 3px #888;
    -webkit-box-shadow: 3px 3px 3px #888;
    -o-box-shadow: 3px 3px 3px #888;
    box-shadow: 3px 3px 3px #888;
    filter: progid: DXImageTransform.Microsoft.Shadow(color='#888888', Direction=135, Strength=5);
    background-color: #fff;
    cursor: default;
    padding: 10px;
    position: fixed;
    z-index: 9;
    font-family: Tahoma;
    opacity: 1
}

.dhx_cal_checkbox label {
    padding-left: 5px
}

.dhx_cal_light .radio {
    padding: 2px 0 2px 10px
}

.dhx_cal_light .radio input,
.dhx_cal_light .radio label {
    line-height: 15px
}

.dhx_cal_light .radio input {
    vertical-align: middle;
    margin: 0;
    padding: 0
}

.dhx_cal_light .radio label {
    vertical-align: middle;
    padding-right: 10px
}

.dhx_cal_light .combo {
    padding: 4px
}

.dhx_cal_light_wide .combo>div,
.dhx_cal_light_wide .dhx_combo_box {
    width: 608px!important;
    left: 10px
}

.dhx_wa_column {
    float: left
}

.dhx_wa_scale_bar {
    font-family: Tahoma;
    padding-left: 10px;
    font-size: 11px
}

.dhx_wa_day_data {
    background-color: #FCFEFC;
    overflow-y: auto
}

.dhx_wa_ev_body {
    font-size: 12px;
    padding: 5px 0 5px 7px
}

.dhx_wa_dnd {
    font-family: Tahoma;
    position: absolute;
    padding-right: 7px;
    color: #887AE2!important;
    background-color: #FFE763!important
}

.dhx_cal_event_selected {
    background-color: #9cc1db;
    color: #fff
}

.dhx_second_scale_bar {
    padding-top: 2px
}

.dhx_grid_area {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #FCFEFC
}

.dhx_grid_area table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed
}

.dhx_grid_area td {
    table-layout: fixed;
    text-align: center
}

.dhx_grid_line {
    height: 21px;
    clear: both;
    overflow: hidden
}

.dhx_grid_line div {
    float: left;
    cursor: default;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    line-height: 21px;
    overflow: hidden
}

.dhx_grid_area td,
.dhx_grid_line div {
    padding-left: 8px;
    padding-right: 8px
}

.dhx_grid_area tr.dhx_grid_event {
    height: 21px;
    overflow: hidden;
    margin: 0 0 1px
}

.dhx_grid_area tr.dhx_grid_event td {
    border-bottom: 1px solid #ECEEF4
}

.dhx_grid_area tr.dhx_grid_event:nth-child(2n) td,
.dhx_grid_area tr.dhx_grid_event:nth-child(2n+1) td {
    border-bottom-width: 0;
    border-bottom-style: none
}

.dhx_grid_area tr.dhx_grid_event:nth-child(2n) {
    background-color: #ECEEF4
}

.dhx_grid_area .dhx_grid_dummy {
    table-layout: auto;
    margin: 0!important;
    padding: 0!important
}

.dhx_grid_v_border {
    position: absolute;
    border-right: 1px solid #A4BED4;
    width: 1px;
    height: 100%
}

.dhx_grid_event_selected {
    background-color: #9cc1db!important;
    color: #fff!important
}

.dhx_grid_sort_desc .dhx_grid_view_sort {
    background-position: 0 -55px
}

.dhx_grid_sort_asc .dhx_grid_view_sort {
    background-position: 0 -66px
}

.dhx_grid_view_sort {
    width: 10px;
    height: 10px;
    position: absolute;
    border: none!important;
    top: 5px;
    background-repeat: no-repeat;
    background-image: url(imgs/images.png)
}

.dhx_marked_timespan {
    position: absolute;
    width: 100%;
    margin-left: 0
}

.dhx_time_block {
    position: absolute;
    width: 100%;
    background: silver;
    opacity: .4;
    filter: alpha(opacity=40);
    z-index: 1
}

.dhx_time_block_reset {
    opacity: 1;
    filter: alpha(opacity=100)
}

.dhx_mini_calendar .dhx_marked_timespan,
.dhx_scheduler_month .dhx_marked_timespan {
    display: none
}

.dhx_now_time {
    width: 100%;
    border-bottom: 2px solid red;
    z-index: 1
}

.dhx_scheduler_month .dhx_now_time {
    border-bottom: 0;
    border-left: 2px solid red
}

.dhx_matrix_now_time {
    border-left: 2px solid red;
    z-index: 1
}

.dhx_matrix_now_time,
.dhx_now_time {
    opacity: .5
}

.dhx_cal_quick_info {
    position: absolute;
    z-index: 8;
    background-color: #8e99ae;
    background-color: rgba(98, 107, 127, .5);
    padding-left: 7px;
    transition: left .5s ease, right .5s;
    -moz-transition: left .5s ease, right .5s;
    -webkit-transition: left .5s ease, right .5s;
    -o-transition: left .5s ease, right .5s
}

.dhx_no_animate {
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none
}

.dhx_cal_quick_info.dhx_qi_left .dhx_qi_big_icon {
    float: right
}

.dhx_cal_qi_title {
    padding: 5px 0 10px 5px;
    letter-spacing: 1px
}

.dhx_cal_qi_tdate {
    font-size: 14px
}

.dhx_cal_qi_tcontent {
    font-size: 18px;
    font-weight: 700
}

.dhx_cal_qi_content {
    background-color: #fefefe;
    font-size: 14px;
    color: #444;
    width: 275px;
    overflow: hidden
}

.dhx_qi_big_icon {
    margin: 5px 9px 5px 0;
    min-width: 60px;
    line-height: 20px;
    vertical-align: middle;
    padding: 5px 10px 5px 5px;
    cursor: pointer;
    background-color: #fefefe
}

.dhx_cal_qi_controls div {
    float: left;
    height: 20px;
    text-align: center;
    line-height: 20px
}

.dhx_qi_big_icon .dhx_menu_icon {
    margin: 0 8px 0 0
}

.dhx_drag_marker {
    width: 100%;
    filter: alpha(Opacity=50);
    opacity: .5;
    background-color: #FFE763;
    position: absolute;
    box-sizing: border-box!important;
    border-top: 1px solid #B6B6B6;
    border-bottom: 1px solid #b6b6b6
}

.dhx_focus_slot {
    background: #FFE763;
    position: absolute;
    pointer-events: none;
    opacity: .3
}

.dhx_cal_container:focus {
    outline-style: auto
}

.dhx_no_select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none
}

.dhx_drag_placeholder {
    z-index: 10;
    opacity: .8
}

.dhx_drag_placeholder .dhx_cal_select_menu {
    display: none
}

.dhtmlx_modal_box *,
.dhx_cal_data,
.dhx_cal_data div,
.dhx_cal_data table *,
.dhx_cal_header,
.dhx_cal_header div,
.dhx_cal_light .dhx_cal_lsection,
.dhx_cal_light .dhx_wrap_section,
.dhx_cal_navline div,
.dhx_cal_quick_info,
.dhx_cal_quick_info div,
.dhx_multi_day,
.dhx_multi_day div,
.dhx_tooltip_line div {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

.dhx_cal_data div.dhx_scale_hour,
.dhx_cal_data table .dhx_matrix_cell,
.dhx_cal_data table .dhx_matrix_scell {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.dhx_cal_data table {
    border-collapse: separate
}

.dhx_cal_light .dhx_cal_radio label {
    margin-bottom: 0
}

.dhx_cal_event div {
    line-height: normal
}

.dhx_cal_container {
    font-size: 8pt;
    position: relative;
    overflow: hidden
}

.dhx_cal_container div {
    -moz-user-select: none;
    -moz-user-select: -moz-none
}

.dhx_cal_navline {
    height: 20px;
    position: absolute;
    z-index: 3;
    width: 750px;
    color: #2F3A48
}

.dhx_cal_navline div {
    position: absolute;
    white-space: nowrap
}

.dhx_cal_navline .dhx_cal_date {
    padding-top: 1px
}

.dhx_cal_button .dhx_left_bg {
    width: 1px;
    overflow: hidden;
    height: 17px;
    z-index: 5;
    top: 0
}

.dhx_cal_prev_button {
    background-image: url(imgs/buttons.png);
    background-position: 0 0;
    cursor: pointer
}

.dhx_cal_next_button {
    background-image: url(imgs/buttons.png);
    background-position: -30px 0;
    cursor: pointer
}

.dhx_cal_today_button {
    background-image: url(imgs/buttons.png);
    background-position: -60px 0;
    cursor: pointer;
    text-align: center
}

.dhx_cal_tab {
    text-align: center;
    cursor: pointer;
    background-color: #D8E1EA;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.dhx_cal_tab.active {
    cursor: default
}

.dhx_cal_header {
    position: absolute;
    left: 10px;
    top: 23px;
    width: 750px;
    z-index: 2;
    overflow: hidden;
    color: #2F3A48
}

.dhx_cal_data {
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: 44px;
    width: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-touch-action: pan-y
}

.dhx_cal_event,
.dhx_cal_event_clear,
.dhx_cal_event_line {
    -ms-touch-action: none
}

.dhx_scale_bar {
    position: absolute;
    text-align: center
}

.dhx_scale_holder,
.dhx_scale_holder_now {
    position: absolute
}

.dhx_scale_hour {
    height: 41px;
    width: 50px;
    text-align: center;
    line-height: 40px;
    overflow: hidden
}

.dhx_month_head {
    padding-right: 5px;
    text-align: right
}

.dhx_scale_ignore {
    display: none
}

.dhx_cal_drag {
    position: absolute;
    z-index: 13;
    background-color: #FFE763;
    border: 1px solid #B7A543;
    opacity: .5;
    filter: alpha(opacity=50)
}

.dhx_loading {
    position: absolute;
    width: 128px;
    height: 15px;
    background-image: url(/img/dhtmlxscheduler/loading.gif);
    z-index: 13
}

.dhx_multi_day,
.dhx_multi_day_icon {
    background-color: #E1E6FF;
    background-repeat: no-repeat;
    border-right: 1px dotted #8894A3
}

.dhx_multi_day {
    position: absolute
}

.dhx_multi_day_icon,
.dhx_multi_day_icon_small {
    background-position: center center;
    background-repeat: no-repeat
}

.dhtmlxLayoutPolyContainer_dhx_skyblue .dhx_cal_container {
    background-color: #d0e5ff
}

.dhx_form_repeat,
.dhx_form_repeat input {
    padding: 0 0 0 5px;
    margin: 0;
    font-family: Tahoma, Verdana;
    font-size: 11px;
    line-height: 24px
}

.dhx_form_repeat {
    overflow: hidden
}

.dhx_cal_light_wide .dhx_form_repeat {
    background-color: transparent
}

.dhx_repeat_center,
.dhx_repeat_divider,
.dhx_repeat_left,
.dhx_repeat_right {
    height: 115px;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.dhx_repeat_center,
.dhx_repeat_left {
    padding: 10px 0 0 10px;
    float: left
}

.dhx_repeat_left {
    width: 105px
}

.dhx_repeat_center {
    width: 345px;
    padding-top: 22px
}

.dhx_repeat_divider {
    float: left;
    width: 1px
}

.dhx_repeat_right {
    float: right;
    width: 173px;
    padding: 17px 3px 0 10px
}

input.dhx_repeat_text {
    height: 16px;
    width: 27px;
    margin: 0 4px;
    line-height: 18px;
    padding: 0 0 0 2px
}

.dhx_form_repeat select {
    height: 20px;
    width: 87px;
    padding: 0 0 0 2px;
    margin: 0 4px
}

input.dhx_repeat_date {
    height: 18px;
    width: 80px;
    padding: 0 0 0 2px;
    margin: 0 4px;
    background-repeat: no-repeat;
    background-position: 64px 0;
    border: 1px solid #7f9db9;
    line-height: 18px
}

input.dhx_repeat_radio {
    margin-right: 4px
}

input.dhx_repeat_checkbox {
    margin: 4px 4px 0 0
}

.dhx_repeat_days td {
    padding-right: 5px
}

.dhx_repeat_days label {
    font-size: 10px
}

.dhx_custom_button {
    width: 90px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px
}

.dhx_custom_button_recurring {
    background-position: -5px 20px;
    width: 20px;
    margin-right: 10px
}

.dhx_cal_light_rec {
    width: 640px
}

.dhx_cal_light_rec .dhx_cal_larea {
    width: 632px
}

.dhx_cal_light_rec.dhx_cal_light_wide {
    width: 816px
}

.dhx_cal_light_rec.dhx_cal_light_wide .dhx_cal_larea {
    width: 808px
}

.dhx_cal_event .dhx_title {
    border-width: 1px 1px 0;
    -webkit-border-top-left-radius: 1px;
    -webkit-border-top-right-radius: 1px;
    -moz-border-radius-topleft: 1px;
    -moz-border-radius-topright: 1px;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    font-family: "Segoe UI", Arial;
    font-weight: 700;
    font-size: 12px;
    height: 14px;
    line-height: 14px
}

.dhx_cal_event .dhx_body,
.dhx_cal_event.dhx_cal_select_menu .dhx_body {
    padding-bottom: 8px;
    -webkit-border-bottom-right-radius: 1px;
    -webkit-border-bottom-left-radius: 1px;
    -moz-border-radius-bottomright: 1px;
    -moz-border-radius-bottomleft: 1px;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.dhx_cal_event .dhx_header,
.dhx_cal_event.dhx_cal_select_menu .dhx_footer {
    display: none
}

.dhx_cal_event .dhx_footer {
    height: 15px;
    border: 0;
    background-color: transparent!important;
    margin-top: -11px
}

.dhx_cal_event .dhx_footer:hover {
    background: url(imgs_flat/resizing.png) center center no-repeat
}

.dhx_cal_event .dhx_body,
.dhx_cal_event .dhx_header,
.dhx_cal_event .dhx_title {
    background-color: #5B9BE0;
    border-color: transparent;
    color: #fff
}

.dhx_cal_event,
.dhx_cal_event_line {
    -webkit-box-shadow: 0 0 1px #FFF;
    -moz-box-shadow: 0 0 1px #FFF;
    box-shadow: 0 0 1px #FFF
}

.dhx_cal_event.dhx_cal_editor {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent
}

.dhx_cal_editor {
    font-size: 12px;
    font-family: "Segoe UI", Arial
}

div.dhx_menu_head,
div.dhx_menu_icon {
    background-image: url(imgs_flat/controls.png)
}

.dhx_cal_event_line {
    border: 1px solid transparent;
    background-color: #5B9BE0;
    color: #FFF;
    height: 17px;
    line-height: 16px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px
}

.dhx_cal_event_line:hover div.dhx_event_resize_start,
.dhx_cal_event_line:hover div.dhx_event_resize_end
{
    background: url(/img/dhtmlxscheduler/resize_dots.png) repeat-y
}

.dhx_cal_event_line_start {
    -webkit-border-top-left-radius: 1px;
    -webkit-border-bottom-left-radius: 1px;
    -moz-border-radius-topleft: 1px;
    -moz-border-radius-bottomleft: 1px;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px
}

.dhx_cal_event_line_end {
    -webkit-border-top-right-radius: 1px;
    -webkit-border-bottom-right-radius: 1px;
    -moz-border-radius-topright: 1px;
    -moz-border-radius-bottomright: 1px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px
}

.dhx_cal_event .dhx_body,
.dhx_cal_event_line {
    font-size: 12px;
    font-family: "Segoe UI", Arial
}

.dhx_cal_event:hover .dhx_footer {
    background: url(/img/dhtmlxscheduler/resizing.png) center center no-repeat
}

.dhx_cal_container {
    background-color: #fff;
    font-family: "Segoe UI", Arial
}

.dhx_cal_data {
    border-top: 1px solid #CECECE
}

.dhx_scale_holder {
    background-image: url(imgs_flat/databg.png);
    border-right: 1px solid #CECECE
}

.dhx_scale_holder_now {
    background-image: url(imgs_flat/databg_now.png);
    border-right: 1px solid #CECECE
}

.dhx_scale_hour {
    border-bottom: 1px solid #CECECE;
    background-color: #fff;
    font: 11px/44px Arial;
    font-family: "Segoe UI", Arial;
    font-weight: lighter;
    color: #767676
}

.dhx_scale_h {
    display: inline-block;
    font-size: 22px
}

.dhx_scale_m {
    display: inline-block;
    font-size: 11px;
    line-height: 35px;
    vertical-align: top
}

.dhx_cal_header {
    border: 1px solid #CECECE;
    border-left: 0;
    border-bottom: 0
}

.dhx_scale_bar {
    border-left: 1px solid #CECECE;
    font: 12px/16px "Segoe UI", Arial;
    color: #767676;
    line-height: 30px;
    padding-top: 2px;
    background-color: #fff
}

.dhx_scale_bar>a {
    color: #5780AD;
    text-decoration: none
}

.dhx_scale_bar>a:hover {
    text-decoration: underline
}

.dhx_month_head>a {
    color: #5780AD;
    text-decoration: none
}

.dhx_month_head>a:hover {
    text-decoration: underline
}

.dhx_cal_navline div {
    top: 14px
}

.dhx_cal_date,
.dhx_cal_next_button,
.dhx_cal_prev_button,
.dhx_cal_tab,
.dhx_cal_today_button {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
    color: #454544;
    height: 30px;
    line-height: 30px;
    background: 0 0;
    font-family: "Segoe UI", Arial;
    font-weight: lighter
}

.dhx_cal_navline .dhx_cal_date {
    border: 0;
    color: #454544;
    font-family: "Segoe UI", Arial;
    font-weight: lighter;
    font-size: 22px;
    text-align: center;
    top: 14px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1
}

.dhx_cal_today_button {
    background: 0 0;
    color: #5780AD;
    font-size: 13px;
    font-weight: bolder;
    left: auto;
    right: 123px;
    text-decoration: none;
    width: 80px
}

.dhx_cal_next_button,
.dhx_cal_prev_button {
    left: auto;
    width: 46px
}

.dhx_cal_prev_button {
    right: 61px;
    background: url(/img/dhtmlxscheduler/arrow_left.png) center center no-repeat
}

.dhx_cal_next_button {
    right: 14px;
    background: url(/img/dhtmlxscheduler/arrow_right.png) center center no-repeat
}

.dhx_cal_tab {
    color: #5780AD;
    font-size: 13px;
    font-weight: bolder;
    padding-top: 0;
    text-decoration: none;
    width: 60px
}

.dhx_cal_tab:hover,
.dhx_cal_today_button:hover {
    text-decoration: underline
}

.dhx_cal_tab.active {
    background-color: #5780AD;
    border: none;
    color: #FFF;
    font-family: "Segoe UI", Arial;
    font-weight: lighter;
    text-decoration: none
}

.dhx_cal_tab_standalone {
    padding: 0 5px
}

.dhx_multi_day,
.dhx_multi_day_icon,
.dhx_multi_day_icon_small {
    background-color: #fff
}

.dhx_multi_day {
    border-top: 1px solid #CECECE
}

.dhx_multi_day_icon,
.dhx_multi_day_icon_small {
    border-bottom: 1px solid #CECECE;
    border-right: 1px solid #CECECE
}

.dhx_multi_day_icon_small {
    background-image: url(imgs_flat/clock.png)
}

.dhx_multi_day_icon {
    background-image: url(imgs_flat/clock_big.gif)
}

.dhx_month_body,
.dhx_month_head {
    background-color: #fff
}

.dhx_after .dhx_month_body,
.dhx_after .dhx_month_head,
.dhx_before .dhx_month_body,
.dhx_before .dhx_month_head {
    background-color: #F7F7F7
}

.dhx_month_head {
    height: 21px;
    padding-top: 0;
    font: 12px/21px "Segoe UI", Arial;
    color: #5780AD;
    border-right: 1px solid #CECECE
}

.dhx_after .dhx_month_head,
.dhx_before .dhx_month_head {
    color: #bbb
}

.dhx_month_body {
    border-right: 1px solid #CECECE;
    border-bottom: 1px solid #CECECE
}

.dhx_now .dhx_month_body,
.dhx_now .dhx_month_head {
    background-color: #FFF3A1;
    font-weight: 400
}

.dhx_cal_event_clear {
    color: #0E64A0
}

.dhx_cal_larea {
    margin-left: 0
}

.dhx_cal_light_wide .dhx_cal_larea {
    margin-left: 3px
}

.dhx_cal_light_wide .dhx_wrap_section {
    padding: 5px 0
}

.dhx_cal_larea,
.dhx_cal_light,
.dhx_cal_light_wide .dhx_wrap_section,
.dhx_cal_lsection,
.dhx_cal_ltext textarea,
.dhx_wrap_section {
    background-color: #fff;
    font-family: "Segoe UI", Arial
}

.dhx_cal_light input,
.dhx_cal_light_wide .dhx_cal_lsection .dhx_fullday,
.dhx_cal_lsection,
.dhx_cal_lsection .dhx_fullday {
    color: #444;
    font-family: "Segoe UI", Arial
}

.dhx_cal_light_wide .dhx_wrap_section {
    border-top: 0;
    border-bottom: 1px solid #CECECE
}

.dhx_cal_larea {
    border: 1px solid transparent
}

.dhx_cal_light,
.dhx_cal_light select,
.dhx_cal_ltext textarea {
    color: #2E2E2E
}

.dhx_cal_light {
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.dhx_cal_light_wide .dhx_cal_lsection,
.dhx_cal_light_wide .dhx_cal_lsection .dhx_fullday {
    font-family: "Segoe UI", Arial;
    font-size: 13px;
    font-weight: 700
}

.dhx_section_time {
    background-color: transparent
}

.dhx_btn_set div:first-child,
.dhx_cancel_btn,
.dhx_delete_btn,
.dhx_save_btn {
    display: none
}

.dhx_btn_set,
.dhx_btn_set div {
    height: 30px;
    padding: 0 20px;
    line-height: 30px
}

.dhx_btn_set {
    margin: 12px 0 0;
    padding: 0;
    font-size: 13px;
    color: #444;
    font-weight: 400;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.dhx_left_btn_set {
    margin-left: 20px
}

.dhx_right_btn_set {
    margin-right: 20px
}

.dhx_save_btn_set {
    border: none;
    color: #FFF;
    background-color: #5B9BE0
}

.dhx_cancel_btn_set {
    background-color: #FE7510;
    color: #FFF
}

.dhx_delete_btn_set {
    border: 1px solid #888;
    color: #444
}

.dhx_cal_ltitle {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #CECECE
}

.dhx_cal_ltitle span {
    float: left
}

.dhx_cal_light .dhx_title {
    font-size: 13px;
    padding-left: 13px
}

.dhx_mark {
    display: none
}

.dhx_time {
    font-size: 12px;
    padding-left: 10px
}

.dhx_close_icon {
    float: right;
    width: 9px;
    height: 9px;
    background: url(imgs_flat/close_icon.png) center center no-repeat;
    padding: 10px;
    margin-top: 1px
}

.dhx_cal_light_wide .dhx_cal_ltext.dhx_cal_template {
    line-height: 22px
}

.dhx_cal_ltext textarea {
    line-height: 20px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid #CECECE;
    background-color: #F9F9F9
}

.dhtmlx_modal_box {
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: #fff;
    font-family: "Segoe UI", Arial;
    width: 330px
}

.dhtmlx_popup_text {
    font-size: 16px
}

.dhtmlx_popup_controls {
    padding-bottom: 9px
}

.dhtmlx_popup_button,
.dhtmlx_popup_button div,
.dhtmlx_popup_button div:active,
.dhtmlx_popup_button:active {
    color: #444;
    background: #fff;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.dhtmlx_popup_button {
    border: 1px solid #888
}

.dhtmlx_popup_button div {
    border: none
}

.dhtmlx_popup_button.dhtmlx_ok_button {
    border-color: #5B9BE0;
    background-color: #5B9BE0;
    color: #FFF
}

.dhtmlx_popup_button.dhtmlx_cancel_button {
    border-color: #FE7510;
    background-color: #FE7510;
    color: #FFF
}

.dhtmlx_popup_button.dhtmlx_cancel_button div,
.dhtmlx_popup_button.dhtmlx_ok_button div {
    background: 0 0;
    color: #FFF
}

.dhx_cal_container.dhx_mini_calendar {
    box-sizing: border-box;
    border: none;
    box-shadow: 0 2px 10px 0 #AAA;
    border-radius: 0
}

.dhx_mini_calendar .dhx_year_month {
    border: 1px solid #CECECE;
    font-family: "Segoe UI", Arial
}

.dhx_mini_calendar .dhx_month_body,
.dhx_mini_calendar .dhx_month_head,
.dhx_mini_calendar .dhx_scale_bar,
.dhx_mini_calendar .dhx_year_body,
.dhx_mini_calendar .dhx_year_month {
    border-color: transparent
}

.dhx_mini_calendar .dhx_year_body {
    padding-top: 1px
}

.dhx_mini_calendar .dhx_scale_bar {
    border-width: 0
}

.dhx_mini_calendar .dhx_year_week {
    border-bottom: 1px solid #CECECE;
    padding-top: 1px
}

.dhx_mini_calendar .dhx_month_head {
    padding-right: 0;
    text-align: center
}

.dhx_mini_calendar .dhx_cal_next_button,
.dhx_mini_calendar .dhx_cal_prev_button {
    border: 0;
    height: 20px
}

.dhx_cal_navline div.dhx_minical_icon {
    left: 210px;
    top: 14px;
    width: 30px;
    height: 30px;
    background: url(imgs_flat/calendar.gif) 3px 5px no-repeat
}

.dhx_cal_event_line .dhx_event_resize {
    background: 0 0
}

.dhx_cal_event_line .dhx_event_resize:hover {
    background: url(/img/dhtmlxscheduler/resize_dots.png) repeat-y
}

.dhx_matrix_cell,
.dhx_matrix_scell {
    border-bottom: 1px solid #CECECE;
    border-right: 1px solid #CECECE
}

.dhx_cal_header div div {
    border-left: 1px solid #CECECE
}

.dhx_matrix_scell {
    font-size: 13px
}

.dhx_second_scale_bar {
    border-bottom: 1px solid #CECECE
}

.dhx_repeat_divider {
    border-left: 1px solid #CECECE
}

.dhx_custom_button {
    background-color: #fff;
    border: 1px solid #a4a4a4;
    border-radius: 0;
    color: #444
}

.dhx_cal_light_wide .dhx_custom_button {
    margin-top: 6px
}

.dhx_custom_button_recurring {
    background-image: url(imgs_flat/but_repeat.gif)
}

input.dhx_repeat_date {
    border-color: #a8a8a8
}

.dhx_form_repeat {
    background-color: transparent
}

.dhx_agenda_line div,
.dhx_v_border {
    border-right: 1px solid #CECECE
}

.dhx_cal_header .dhx_agenda_line,
.dhx_cal_header .dhx_agenda_line div {
    height: 100%;
    line-height: 35px
}

.dhx_agenda_area,
.dhx_scheduler_agenda .dhx_cal_data {
    background-image: url(imgs_flat/agenda_bg.gif)
}

.dhx_agenda_line {
    height: 26px
}

.dhx_agenda_line div,
.dhx_agenda_line span {
    line-height: 26px
}

.dhx_agenda_line .dhx_event_icon {
    background-position: 5px 6px
}

.dhx_year_month {
    border: 1px solid #CECECE
}

.dhx_scale_bar_last {
    border-right: 1px solid #CECECE
}

.dhx_year_body {
    border-left: 1px solid #CECECE
}

.dhx_expand_icon {
    top: -3px;
    background-image: url(imgs_flat/collapse_expand_icon.gif)
}

.dhx_scale_bar .dhx_cal_next_button,
.dhx_scale_bar .dhx_cal_prev_button {
    width: 20px;
    height: 100%;
    top: 0!important;
    border: 0
}

.dhx_scale_bar .dhx_cal_next_button {
    right: 1px!important;
    border-left: 1px solid #CECECE
}

.dhx_scale_bar .dhx_cal_prev_button {
    left: 1px!important;
    border-right: 1px solid #CECECE
}

.dhx_map_line .headline_date,
.dhx_map_line .headline_description {
    border: 0
}

.dhx_map_line .headline_date {
    border-right: 1px solid #CECECE
}

.dhx_map_line.highlight {
    background-color: #b3daff
}

.dhx_cal_header .dhx_map_line {
    height: 100%
}

.dhx_cal_header .dhx_map_line .headline_date,
.dhx_cal_header .dhx_map_line .headline_description {
    height: 100%;
    line-height: 35px
}

.dhx_map_area {
    background-image: url(imgs_flat/agenda_bg.gif)
}

.dhx_map_line {
    height: 26px
}

.dhx_map_line .line_description,
.dhx_map_line div {
    line-height: 26px;
    height: 26px
}

.dhx_map_line .dhx_event_icon {
    background-position: 5px 6px
}

.dhx_cal_data div.dhx_map_area,
.dhx_cal_header div.dhx_map_line {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-right: 1px solid #cecece
}

.dhtmlXTooltip.tooltip {
    border-left: 1px solid #CECECE;
    border-top: 1px solid #CECECE;
    color: #747473;
    font-size: 12px;
    line-height: 16px
}

.dhx_wa_scale_bar {
    border-top: 1px solid #CECECE;
    border-bottom: 1px solid #CECECE
}

.dhx_wa_column_last .dhx_wa_day_cont {
    border-left: 1px solid #CECECE
}

.dhx_wa_ev_body {
    border-bottom: 1px solid #CECECE
}

.dhx_wa_scale_bar {
    background-color: #f0ede7
}

.dhx_wa_ev_body.dhx_cal_event_selected {
    background-color: #fff3a1;
    color: #362d26
}

.dhx_wa_dnd {
    background-color: #fddb93!important;
    color: #747473!important;
    border: 1px solid #ccb177
}

.dhx_text_disabled {
    color: #2E2E2E
}

.dhx_cal_ltext .dhx_text_disabled {
    line-height: 22px
}

.dhx_grid_v_border {
    border-right-color: #CECECE
}

.dhx_cal_header .dhx_grid_line,
.dhx_cal_header .dhx_grid_line div {
    height: 100%;
    line-height: 35px
}

.dhx_cal_header .dhx_grid_line div.dhx_grid_view_sort {
    height: 10px;
    top: 12px
}

.dhx_month_body_border,
.dhx_month_head_border,
.dhx_scale_bar_border,
.dhx_scale_hour_border {
    border-left: 1px solid #CECECE
}

.dhx_cal_quick_info {
    background: rgba(204, 204, 204, .8);
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-family: "Segoe UI", Arial;
    width: 280px
}

.dhx_cal_qi_title {
    color: #000
}

.dhx_cal_qi_tdate {
    color: #444
}

.dhx_cal_qi_content {
    background: 0 0;
    border: none;
    padding: 10px 8px
}

.dhx_qi_big_icon {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
    color: #FFF;
    float: none;
    font-size: 13px
}

.dhx_qi_big_icon .dhx_menu_icon {
    display: none!important
}

.dhx_cal_quick_info .icon_details {
    background: #5B9BE0;
    margin-left: 100px
}

.dhx_cal_quick_info .icon_details div {
    padding-left: 12px
}

.dhx_cal_quick_info .icon_delete {
    background: #FE7510
}

.dhx_cal_quick_info .icon_delete div {
    padding-left: 12px
}

.dhx_cal_navline .dhx_cal_export {
    width: 32px;
    height: 32px;
    margin: 2px;
    cursor: pointer;
    top: 12px
}

.dhx_cal_navline .dhx_cal_export.pdf {
    left: auto;
    right: 249px;
    background-image: url(imgs_flat/export_pdf.png)
}

.dhx_cal_navline .dhx_cal_export.ical {
    left: auto;
    right: 210px;
    background-image: url(imgs_flat/export_ical.png)
}

.dhx_mini_calendar {
    font-family: "Segoe UI", Arial;
    padding: 5px
}

.dhx_mini_calendar .dhx_calendar_click,
.dhx_mini_calendar .dhx_year_event {
    border-radius: 0
}

.dhx_mini_calendar .dhx_month_head {
    color: #363636;
    font-family: "Segoe UI", Arial;
    font-size: 13px;
    margin: 2px
}

.dhx_mini_calendar .dhx_year_month {
    color: #454544;
    line-height: 20px;
    height: 25px;
    font-size: 20px;
    font-weight: lighter
}

.dhx_mini_calendar .dhx_cal_next_button,
.dhx_mini_calendar .dhx_cal_prev_button {
    top: 8px!important
}

.dhx_mini_calendar .dhx_scale_bar {
    font-family: "Segoe UI", Arial;
    font-size: 11px
}
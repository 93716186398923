form .chosen-container 
{
    width: 100% !important;
    margin-bottom: 0px !important;
}

form .chosen-container-single .chosen-single, 
form .chosen-container-single.chosen-with-drop .chosen-single 
{
    height: 35px !important;
    line-height: 35px !important;
}

/* .has-columns-hidden tbody tr td */
/* { */
    /* text-align: left; */
/* } */

.has-columns-hidden li 
{
    text-align: left;
}

.has-columns-hidden .form-group-table 
{
    width: auto !important;
}

.has-columns-hidden .input-group 
{
    width: 100%;
}

.has-columns-hidden .ckbox.ckbox-theme
{
    margin-top: -30px !important;
}

body.page-sidebar-minimize #sidebar-left:hover .sidebar-menu > li, 
body.page-sidebar-minimize-auto #sidebar-left:hover .sidebar-menu > li {
width:75px;
}

body.page-sidebar-minimize #sidebar-left .sidebar-menu > li:hover, 
body.page-sidebar-minimize-auto #sidebar-left:hover .sidebar-menu > li:hover {
width:250px;

}

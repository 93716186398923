/* ========================================================================
 * TABLE OF CONTENTS
 * ========================================================================
   JQUERY SPARKLINE
   GMAP3
   ICONS MAP
   JQVMAP
   FlOT CHART
   MORRIS CHART
   C3JS CHART
   CHARTJS CHART
   CHOOSE THEMES
   DROPZONE
   BOOTSTRAP TAG INPUT
   BOOTSTRAP FILE UPLOAD
   TYPEAHEAD
   CALENDAR
   BOOTSTRAP SWITCH
   BOOTSTRAP DATEPICKER
   JQUERY COLORPICKER
   JASNY BOOTSTRAP
   CHOSEN SELECT
   ION RANGESLIDER
   SUMMERNOTE
   WYSIHTML5
   DATATABLE
   FONT AWESOME
   GRITTER NOTIFICATION
   NICESCROLL
   FUEL UX
   CODE PRETTIFY
   BLOCKUI
   CUBEPORTFOLIO
   PEITY CHARTS
   EASY PIE CHART
   BOOTSTRAP COLOR PICKER
   BOOTSTRAP DATE RANGE PICKER
   GRID STACK
   SELECT2
 * ======================================================================== */
/* ========================================================================
 * JQUERY SPARKLINE
 * ======================================================================== */
.jqstooltip {
  min-width: 30px;
  height: 25px !important;
  text-align: center;
}

/* ========================================================================
 * GMAP3
 * ======================================================================== */
#panel-box {
  background-color: rgba(255, 255, 255, 0.51);
  width: 180px;
  height: 64px;
  font-size: 11px;
  padding: 5px;
}
#panel-box:hover {
  background: #FFFFFF;
}
#panel-box .line-panel {
  overflow: auto;
  clear: both;
  height: 18px;
}
#panel-box #lng-west {
  border-bottom: 0px;
}
#panel-box .name {
  width: 50px;
  float: left;
  font-size: 13px;
  line-height: 16px;
}
#panel-box .value {
  float: left;
  font-size: 11px;
  line-height: 15px;
}
#panel-box .value:before {
  content: ": ";
}

.map {
  width: 100%;
  height: 300px;
}
.map.map-lg {
  height: 400px;
}

.map-continent {
  margin-left: auto;
  margin-right: auto;
  height: 300px;
}

/* ========================================================================
 * ICONS MAP
 * ======================================================================== */
.marker-label,
.marker-icon {
  z-index: 99;
  position: absolute;
  display: block;
  margin-top: -50px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  font-size: 30px !important;
  text-align: center;
  color: #FFFFFF;
  white-space: nowrap;
}

.icon-maps-example {
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.icon-maps-example span {
  font-size: 20px;
  vertical-align: middle;
}

/* ========================================================================
 * JQVMAP
 * ======================================================================== */
.resize-map {
  width: 100%;
  height: 450px;
}

.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 0px;
  background: #505050;
  color: white;
  font-size: 0.9em;
  padding: 5px 10px;
  border-top: 5px solid #2A2A2A;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute !important;
  left: 10px !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50%;
  border-radius: 50% !important;
  padding: 3px !important;
  color: #969fa1 !important;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer !important;
  line-height: 0px !important;
  font-size: 0px !important;
  text-align: center !important;
  transition: background 0.2s;
}

.jqvmap-zoomin {
  top: 10px !important;
}
.jqvmap-zoomin:before {
  font-family: FontAwesome;
  color: #fff;
  text-align: center;
  line-height: 25px;
  font-size: 16px;
  content: "\f067";
}

.jqvmap-zoomout {
  top: 45px !important;
}
.jqvmap-zoomout:before {
  font-family: FontAwesome;
  color: #fff;
  text-align: center;
  line-height: 25px;
  font-size: 16px;
  content: "\f068";
}

.jqvmap-region {
  cursor: pointer;
}

.jqvmap-ajax_response {
  width: 100%;
  height: 500px;
}

/* ========================================================================
 * FlOT CHART
 * ======================================================================== */
#flotTip {
  font-size: 12px !important;
  font-weight: 600;
  position: relative;
  z-index: 5;
  padding: 4px 5px !important;
  border-radius: 3px !important;
  border-color: transparent !important;
  background-color: #444 !important;
  color: #EEE !important;
}

.tooltipflot {
  background-color: #2A2A2A;
  color: #FFFFFF !important;
  padding: 2px 4px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  font-size: 12px;
}

.legendColorBox > div {
  margin-right: 5px;
  border: none !important;
}

/* ========================================================================
 * MORRIS CHART
 * ======================================================================== */
.morris-hover {
  padding: 0px 0px 10px 0px;
  text-align: center;
}
.morris-hover.morris-default-style {
  background-color: #FFFFFF !important;
  position: absolute !important;
  min-width: 100px;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  margin-bottom: 10px;
  padding: 5px 0px;
  text-align: center;
}
.morris-hover.morris-default-style .morris-hover-point {
  padding: 0px 10px;
  text-align: left;
}

/* ========================================================================
 * C3JS CHART
 * ======================================================================== */
.c3-region.success {
  background-color: #8CC152;
}

.c3-region.warning {
  background-color: #F6BB42;
}

.c3-region.danger {
  background-color: #E9573F;
}

/* ========================================================================
 * CHARTJS CHART
 * ======================================================================== */
canvas.chartjs {
  width: 100% !important;
  max-width: 800px;
  height: auto !important;
}

/* ========================================================================
 * DROPZONE
 * ======================================================================== */
.dropzone {
  padding: 20px 0px 0px 0px !important;
  border: none !important;
}
.dropzone .dz-preview {
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
  padding: 0px !important;
  margin: 0px 0px 20px 20px !important;
}
.dropzone .dz-preview .dz-filename {
  text-align: center !important;
}
.dropzone .dz-preview .dz-details {
  height: 110px !important;
}
.dropzone .dz-preview .dz-details img {
  position: absolute !important;
  top: 5px !important;
  width: 90px !important;
  height: 100px !important;
}
.dropzone .dz-preview .dz-details .dz-size {
  line-height: 32px !important;
}
.dropzone .dz-preview .dz-error-message {
  min-width: 100px !important;
  padding: 0px 10px !important;
  overflow: hidden !important;
  height: 0px !important;
}
.dropzone .dz-error {
  width: 102px !important;
  height: 134px !important;
}

.dz-size {
  left: 0 !important;
  right: 0 !important;
  text-align: center !important;
}

.dropzone {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.dropzone.dz-started .dz-default.dz-message {
  display: none !important;
}
.dropzone .dz-default.dz-message {
  position: relative !important;
  width: 265px !important;
  height: 257px !important;
  top: inherit !important;
  left: inherit !important;
  margin: 25px auto !important;
  background-image: url("../../../assets/global/img/plugins/dropzone/uploadfile.png") !important;
  background-size: 150px !important;
  background-position: 50% !important;
}

@media (max-width: 320px) {
  .dropzone .dz-default.dz-message {
    background-position: -15px 0px !important;
    background-size: 205px;
    margin-left: 50px !important;
  }
}
.dropzone .dz-preview, .dropzone-previews .dz-preview {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #DDD !important;
}

.dz-file-preview .dz-details:before {
  font-family: "Glyphicons Halflings" !important;
  content: "\e117" !important;
  font-size: 45px !important;
  position: absolute !important;
  top: 50px !important;
  left: 27px !important;
}

@media (max-width: 360px) {
  .dropzone .dz-preview {
    margin-bottom: 30px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    width: 114px !important;
  }
}
/* ========================================================================
 * BOOTSTRAP TAG INPUT
 * ======================================================================== */
input.rounded + .bootstrap-tagsinput {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
input.rounded + .bootstrap-tagsinput .tag {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.bootstrap-tagsinput {
  padding: 5px 7px 7px;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  width: 100%;
}
.bootstrap-tagsinput input {
  border: none !important;
}
.bootstrap-tagsinput input:focus {
  border: none !important;
}
.bootstrap-tagsinput .tag {
  display: inline-block;
  padding: 5px 7px 8px;
}

@media (max-width: 360px) {
  .bootstrap-tagsinput .tag {
    margin-bottom: 4px;
  }
}
/* ========================================================================
 * BOOTSTRAP FILE UPLOAD
 * ======================================================================== */
.fileupload .btn {
  margin-left: 0px;
  line-height: 20px;
}

.fileupload-new .input-append .btn-file {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.uneditable-input {
  width: 155px !important;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  height: 32px;
}

/* ========================================================================
 * TYPEAHEAD
 * ======================================================================== */
.tt-hint {
  color: #999;
}

.empty-message {
  padding: 20px;
}

.tt-dropdown-menu {
  top: 45px !important;
  width: 350px;
  max-height: 360px;
  background-color: #fff;
  border-bottom: 1px solid #DDD;
  overflow-y: auto;
  -webkit-border-bottom-left-radius: 3px !important;
  -moz-border-radius-bottomleft: 3px !important;
  border-bottom-left-radius: 3px !important;
  -webkit-border-bottom-right-radius: 3px !important;
  -moz-border-radius-bottomright: 3px !important;
  border-bottom-right-radius: 3px !important;
}
.tt-dropdown-menu a.media {
  display: block;
  text-decoration: none;
  border-bottom: 1px solid #DDD;
}
.tt-dropdown-menu a.media:hover {
  background-color: #EEEEEE;
}
.tt-dropdown-menu .media {
  position: relative;
  padding: 8px 15px;
}
.tt-dropdown-menu .media.border-dotted:after {
  border-bottom-style: dotted;
}
.tt-dropdown-menu .media .media-heading {
  text-transform: capitalize;
}
.tt-dropdown-menu .media .media-object {
  width: 50px;
  height: 50px;
  text-align: center;
  margin-top: 10px;
}
.tt-dropdown-menu .media .media-object i {
  display: block;
  width: 34px;
  height: 34px;
  margin: 0 auto;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
}
.tt-dropdown-menu .media .media-object i.bg-default {
  background-color: gray;
}
.tt-dropdown-menu .media .pull-left {
  margin-right: 10px;
  display: table-cell;
}
.tt-dropdown-menu .media .media-body {
  display: table-cell;
}
.tt-dropdown-menu .media .media-body .media-text {
  display: block;
  color: #999;
  font-size: 13px;
  margin: 0;
}

.tt-suggestions .repo-language {
  float: right;
  font-style: italic;
}
.tt-suggestions .repo-name {
  font-size: 20px;
  font-weight: bold;
}
.tt-suggestions .repo-description {
  margin: 0;
  color: red !important;
}

/* ========================================================================
 * CALENDAR
 * ======================================================================== */
.calendar-toolbar {
  border-bottom: 5px dotted #DDD;
  padding-bottom: 20px;
  margin-bottom: 15px;
}

.cal-month-box, .cal-year-box, .cal-week-box {
  border: none !important;
}

.cal-month-box, .cal-week-box, .cal-year-box {
  background-color: white;
}

.cal-year-box .row-fluid, .cal-month-box .cal-row-fluid {
  border-bottom: 1px solid #f7f7f7 !important;
}

.cal-year-box [class*="span"], .cal-month-box [class*="cal-cell"] {
  border-right: 1px solid #f7f7f7 !important;
}

.cal-year-box .row-fluid [class*="span"]:last-child {
  border-right: none !important;
}

.cal-month-box .cal-row-fluid [class*="cal-cell"]:last-child {
  border-right: none !important;
}

.cal-month-box span[data-cal-date], .cal-month-day span[data-cal-date] {
  font-size: 25px;
}
.cal-month-box [data-cal-row="-day1"] .cal-day-weekend span[data-cal-date], .cal-month-day [data-cal-row="-day1"] .cal-day-weekend span[data-cal-date] {
  color: #E9573F;
}

.cal-year-box {
  color: #2A2A2A;
}
.cal-year-box .col-md-3 {
  width: 25%;
}
.cal-year-box .col-md-3 span[data-cal-date] {
  margin-right: auto !important;
}

#cal-week-box {
  display: none !important;
}

#cal-day-box .day-highlight {
  border: none !important;
}
#cal-day-box .day-event {
  margin-left: 23px !important;
}

#cal-day-panel #cal-day-panel-hour .cal-day-hour .cal-day-hour-part .span1 {
  padding-top: 4px;
}
#cal-day-panel #cal-day-panel-hour .cal-day-hour .cal-day-hour-part b:before {
  font-family: "fontawesome";
  content: "\f017";
  margin-right: 5px;
}

#cal-slide-content {
  background-color: #F5F5F5 !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #999;
  border-bottom: 1px solid #DDD;
  background-image: none !important;
}
#cal-slide-content a.event-item {
  color: #999;
}

#cal-day-tick {
  background-color: #F5F5F5;
  display: none;
}

#cal-slide-tick {
  display: none !important;
}

.cal-row-head [class*="cal-cell"] {
  padding: 10px 3px;
  font-weight: 400;
}
.cal-row-head [class*="cal-cell"]:first-child {
  padding: 10px 0px;
  font-weight: 400;
}

.cal-row-head + .cal-day-hour {
  padding-top: 15px;
  padding-bottom: 10px;
  padding-right: 15px;
}
.cal-row-head + .cal-day-hour [class*="col-xs-"] {
  padding-left: 0px;
  padding-right: 0px;
}
.cal-row-head + .cal-day-hour [class*="col-xs-"].span1 {
  text-align: center;
}

.event {
  width: 15px !important;
  height: 15px !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px;
  border-radius: 10px !important;
  border: none !important;
}

.event-important {
  background-color: #E9573F !important;
}

.event-info {
  background-color: #63D3E9 !important;
}

.event-warning {
  background-color: #F6BB42 !important;
}

.event-inverse {
  background-color: #2A2A2A !important;
}

.event-success {
  background-color: #8CC152 !important;
}

.event-special {
  background-color: #906094 !important;
}

#eventlist li {
  position: relative;
  display: block;
  font-size: 13px;
}
#eventlist li:first-child {
  border-top: none;
}
#eventlist li a {
  padding: 0px;
  margin-bottom: 10px;
}
#eventlist li a i {
  background-color: white;
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-block;
  line-height: 28px;
  font-size: 14px;
}

#events-modal {
  z-index: 9999;
}

.day-highlight .dh-event-important {
  background-color: #E9573F !important;
  border: none;
}
.day-highlight .dh-event-important:hover {
  background-color: #e64328;
}
.day-highlight .dh-event-important .cal-hours {
  color: #69190c;
}
.day-highlight .dh-event-important a {
  color: white;
}
.day-highlight .dh-event-info {
  background-color: #63D3E9 !important;
  border: none;
}
.day-highlight .dh-event-info:hover {
  background-color: #4dcde6;
}
.day-highlight .dh-event-info .cal-hours {
  color: #137487;
}
.day-highlight .dh-event-info a {
  color: white;
}
.day-highlight .dh-event-warning {
  background-color: #F6BB42 !important;
  border: none;
}
.day-highlight .dh-event-warning:hover {
  background-color: #f5b22a;
}
.day-highlight .dh-event-warning .cal-hours {
  color: #7f5806;
}
.day-highlight .dh-event-warning a {
  color: white;
}
.day-highlight .dh-event-inverse {
  background-color: #2A2A2A !important;
  border: none;
}
.day-highlight .dh-event-inverse:hover {
  background-color: #1d1d1d;
}
.day-highlight .dh-event-inverse .cal-hours {
  color: black;
}
.day-highlight .dh-event-inverse a {
  color: white;
}
.day-highlight .dh-event-success {
  background-color: #8CC152 !important;
  border: none;
}
.day-highlight .dh-event-success:hover {
  background-color: #7fb842;
}
.day-highlight .dh-event-success .cal-hours {
  color: #314719;
}
.day-highlight .dh-event-success a {
  color: white;
}
.day-highlight .dh-event-special {
  background-color: #906094 !important;
  border: none;
}
.day-highlight .dh-event-special:hover {
  background-color: #815685;
}
.day-highlight .dh-event-special .cal-hours {
  color: #271a28;
}
.day-highlight .dh-event-special a {
  color: white;
}

@media (max-width: 800px) {
  .cal-month-box span[data-cal-date], .cal-month-day span[data-cal-date] {
    font-size: 15px;
    margin-top: 5px !important;
    margin-right: 10px !important;
  }
}
@media (max-width: 480px) {
  .cal-year-box .col-md-3 {
    width: 50%;
  }

  .events-list {
    max-height: 60px !important;
  }
}
/* ========================================================================
 * BOOTSTRAP SWITCH
 * ======================================================================== */
.bootstrap-switch {
  min-width: 60px !important;
  border: none !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.bootstrap-switch .bootstrap-switch-container, .bootstrap-switch .bootstrap-switch-on .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-on {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.bootstrap-switch .bootstrap-switch-handle-on, .bootstrap-switch .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label {
  line-height: 15px !important;
  font-size: 11px !important;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-default, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-default, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-default {
  background: #EEEEEE;
  color: white;
  border: 1px solid #EEEEEE;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-default + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-default + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-default + .bootstrap-switch-label {
  border: 1px solid #EEEEEE;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-default + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-default + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-default + .bootstrap-switch-label + .bootstrap-switch-handle-off {
  border: 1px solid #EEEEEE;
  border-left: none;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-primary {
  background: #00B1E1;
  color: white;
  border: 1px solid #00B1E1;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-primary + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-primary + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-primary + .bootstrap-switch-label {
  border: 1px solid #00B1E1;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-primary + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-primary + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-primary + .bootstrap-switch-label + .bootstrap-switch-handle-off {
  border: 1px solid #00B1E1;
  border-left: none;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-success, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-success, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-success {
  background: #8CC152;
  color: white;
  border: 1px solid #8CC152;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-success + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-success + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-success + .bootstrap-switch-label {
  border: 1px solid #8CC152;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-success + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-success + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-success + .bootstrap-switch-label + .bootstrap-switch-handle-off {
  border: 1px solid #8CC152;
  border-left: none;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-info, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-info, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-info {
  background: #63D3E9;
  color: white;
  border: 1px solid #63D3E9;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-info + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-info + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-info + .bootstrap-switch-label {
  border: 1px solid #63D3E9;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-info + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-info + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-info + .bootstrap-switch-label + .bootstrap-switch-handle-off {
  border: 1px solid #63D3E9;
  border-left: none;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-warning, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-warning, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-warning {
  background: #F6BB42;
  color: white;
  border: 1px solid #F6BB42;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-warning + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-warning + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-warning + .bootstrap-switch-label {
  border: 1px solid #F6BB42;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-warning + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-warning + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-warning + .bootstrap-switch-label + .bootstrap-switch-handle-off {
  border: 1px solid #F6BB42;
  border-left: none;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-danger, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-danger, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-danger {
  background: #E9573F;
  color: white;
  border: 1px solid #E9573F;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-danger + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-danger + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-danger + .bootstrap-switch-label {
  border: 1px solid #E9573F;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-danger + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-danger + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-danger + .bootstrap-switch-label + .bootstrap-switch-handle-off {
  border: 1px solid #E9573F;
  border-left: none;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-lilac, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-lilac, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-lilac {
  background: #906094;
  color: white;
  border: 1px solid #906094;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-lilac + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-lilac + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-lilac + .bootstrap-switch-label {
  border: 1px solid #906094;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-lilac + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-lilac + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-lilac + .bootstrap-switch-label + .bootstrap-switch-handle-off {
  border: 1px solid #906094;
  border-left: none;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-inverse, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-inverse, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-inverse {
  background: #2A2A2A;
  color: white;
  border: 1px solid #2A2A2A;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-inverse + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-inverse + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-inverse + .bootstrap-switch-label {
  border: 1px solid #2A2A2A;
}
.bootstrap-switch .bootstrap-switch-handle-on .bootstrap-switch-inverse + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-off .bootstrap-switch-inverse + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label .bootstrap-switch-inverse + .bootstrap-switch-label + .bootstrap-switch-handle-off {
  border: 1px solid #2A2A2A;
  border-left: none;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default, .bootstrap-switch .bootstrap-switch-label.bootstrap-switch-default {
  color: gray !important;
  border: 1px solid #EEEEEE !important;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-label.bootstrap-switch-default + .bootstrap-switch-label {
  border: 1px solid #EEEEEE !important;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label.bootstrap-switch-default + .bootstrap-switch-label + .bootstrap-switch-handle-off {
  border: 1px solid #EEEEEE !important;
  border-left: none !important;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-teal, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-teal, .bootstrap-switch .bootstrap-switch-label.bootstrap-switch-teal {
  background: #37BC9B !important;
  border: 1px solid #37BC9B !important;
  color: white !important;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-teal + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-teal + .bootstrap-switch-label, .bootstrap-switch .bootstrap-switch-label.bootstrap-switch-teal + .bootstrap-switch-label {
  border: 1px solid #37BC9B !important;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-teal + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-teal + .bootstrap-switch-label + .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label.bootstrap-switch-teal + .bootstrap-switch-label + .bootstrap-switch-handle-off {
  border: 1px solid #37BC9B !important;
  border-left: none !important;
}
.bootstrap-switch.bootstrap-switch-focused {
  outline: none !important;
  box-shadow: none !important;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.bootstrap-switch .bootstrap-switch.bootstrap-switch-handle-on
.bootstrap-switch .bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}

/* ========================================================================
 * BOOTSTRAP DATEPICKER
 * ======================================================================== */
.datepicker {
  padding: 0px;
}
.datepicker table thead tr {
  color: white;
}
.datepicker table thead tr:first-child {
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
}
.datepicker table thead tr:first-child th {
  border-bottom: none;
}
.datepicker table thead tr:first-child:first-child th {
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
}
.datepicker table thead tr:first-child:last-child th {
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
}
.datepicker table thead tr th {
  font-weight: 300;
  min-width: 40px;
  vertical-align: middle;
  border-width: 1px 1px 7px;
  border-style: solid;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.datepicker table thead tr .prev, .datepicker table thead tr .switch, .datepicker table thead tr .next {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.datepicker table tbody tr:first-child td {
  border-top: none;
}
.datepicker table tbody tr td {
  min-width: 40px;
  background-color: #EEEEEE;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  padding: 9px;
}
.datepicker table tbody tr td:first-child {
  color: #E9573F;
}
.datepicker table tbody tr td.old, .datepicker table tbody tr td.new {
  background-color: #e6e6e6;
}
.datepicker table tbody tr td.active, .datepicker table tbody tr td.active:hover, .datepicker table tbody tr td.active:disabled, .datepicker table tbody tr td.active.disabled:hover {
  text-shadow: none;
}
.datepicker table tbody tr td.active.active {
  text-shadow: none;
  background-image: none;
  background-repeat: no-repeat;
  color: white;
}
.datepicker table tfoot tr .today {
  background-color: #d5d5d5;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.datepicker table tfoot tr .today:hover {
  background-color: #e1e1e1;
}
.datepicker .icon-arrow-left:before {
  font-family: "FontAwesome";
  content: "\f104";
}
.datepicker .icon-arrow-right:before {
  font-family: "FontAwesome";
  content: "\f105";
}

.datepicker-dropdown {
  border: none;
  box-shadow: none;
}

@media (max-width: 320px) {
  .datepicker-inline table {
    margin-left: -14px !important;
  }
}
/* ========================================================================
 * JQUERY COLORPICKER
 * ======================================================================== */
#colorselector {
  position: relative;
  width: 36px;
  height: 36px;
  background: url("../plugins/jquery-colorpicker/images/select2.png");
}
#colorselector div {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 30px;
  height: 30px;
  background: url("../plugins/jquery-colorpicker/images/select2.png") center;
}

/* ========================================================================
 * JASNY BOOTSTRAP
 * ======================================================================== */
.fileinput-new .form-control {
  border-right: none;
}
.fileinput-new .btn-file {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.fileinput-new.input-group .btn-file {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.fileinput-new .input-group .btn-file {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.close.fileinput-exists {
  color: #E9573F;
}

/* ========================================================================
 * CHOSEN SELECT
 * ======================================================================= */
.chosen-select.rounded + .chosen-container-single .chosen-single {
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
}
.chosen-select.circle + .chosen-container-single .chosen-single {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50%;
  border-radius: 50% !important;
}

.chosen-container {
  width: 100% !important;
  margin-bottom: 10px !important;
}
.chosen-container:last-child {
  margin-bottom: 0px;
}
.chosen-container .chosen-drop {
  border: 1px solid #DDD !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.chosen-container .chosen-results li.group-result {
  background-color: #EEEEEE;
}

.chosen-container-single .chosen-single {
  color: #A2A2A2 !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.chosen-container-single .chosen-drop {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}

.chosen-container-single .chosen-single, .chosen-container-single.chosen-with-drop .chosen-single {
  background: #FFFFFF !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #DDD !important;
  height: 28px !important;
  line-height: 26px !important;
}
.chosen-container-single .chosen-single div b, .chosen-container-single.chosen-with-drop .chosen-single div b {
  background-position: 0px 4px !important;
}

.chosen-container-multi .chosen-choices {
  background-image: none !important;
}

.chosen-container-multi .chosen-choices {
  border: 1px solid #DDD;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  height: 25px;
}

.chosen-container-active .chosen-choices {
  border-top: 1px solid #DDD;
  border-left: 1px solid #DDD;
  border-right: 1px solid #DDD;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.chosen-container-multi .chosen-choices li.search-choice {
  border: none;
  background: #EEEEEE;
}

@media (max-width: 600px) {
  .chosen-container {
    margin-bottom: 15px;
  }
}
/* ========================================================================
 * ION RANGESLIDER
 * ======================================================================= */
#result-rangeSlider {
  position: relative;
  white-space: pre;
  line-height: 1.5;
}

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-diapason,
.irs-slider {
  background-color: #e1e4e9;
  background-repeat: repeat-x;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.irs {
  height: 40px;
}

.irs-with-grid {
  height: 60px;
}

.irs-line {
  height: 12px;
  top: 25px;
}

.irs-line-left {
  height: 12px;
  background-color: #e1e4e9;
}

.irs-line-mid {
  height: 12px;
  background-color: #e1e4e9;
}

.irs-line-right {
  height: 12px;
  background-color: #e1e4e9;
}

.irs-diapason {
  height: 12px;
  top: 25px;
}

.irs-slider {
  width: 16px;
  height: 18px;
  top: 22px;
}

.irs-min, .irs-max {
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: #e1e4e9;
  border-radius: 4px;
}

.irs-from, .irs-to, .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  border-radius: 4px;
}

.irs-from:after, .irs-to:after, .irs-single:after {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
}

.irs-grid-pol {
  background: #e1e4e9;
}

.irs-grid-text {
  color: #999;
}

.slider-teal .irs-line-mid,
.slider-teal .irs-line-left,
.slider-teal .irs-line-right,
.slider-teal .irs-diapason,
.slider-teal .irs-slider {
  background-color: #e1e4e9;
  background-repeat: repeat-x;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.slider-teal .irs {
  height: 40px;
}
.slider-teal .irs-with-grid {
  height: 60px;
}
.slider-teal .irs-line {
  height: 12px;
  top: 25px;
}
.slider-teal .irs-line-left {
  height: 12px;
  background-color: #e1e4e9;
}
.slider-teal .irs-line-mid {
  height: 12px;
  background-color: #e1e4e9;
}
.slider-teal .irs-line-right {
  height: 12px;
  background-color: #e1e4e9;
}
.slider-teal .irs-diapason {
  height: 12px;
  top: 25px;
  background-color: #44c8a8;
}
.slider-teal .irs-slider {
  width: 16px;
  height: 18px;
  top: 22px;
  background-color: #37BC9B;
}
.slider-teal #irs-active-slider, .slider-teal .irs-slider:hover {
  background-color: #37BC9B;
}
.slider-teal .irs-min, .slider-teal .irs-max {
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: #e1e4e9;
  border-radius: 4px;
}
.slider-teal .irs-from, .slider-teal .irs-to, .slider-teal .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #37BC9B;
  border-radius: 4px;
}
.slider-teal .irs-from:after, .slider-teal .irs-to:after, .slider-teal .irs-single:after {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #37BC9B;
}
.slider-teal .irs-grid-pol {
  background: #e1e4e9;
}
.slider-teal .irs-grid-text {
  color: #999;
}
.slider-teal.circle .irs-slider {
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.slider-primary .irs-diapason {
  background-color: #00c5fb !important;
}
.slider-primary .irs-slider {
  background-color: #00B1E1 !important;
}
.slider-primary #irs-active-slider, .slider-primary .irs-slider:hover {
  background-color: #00B1E1 !important;
}
.slider-primary .irs-from, .slider-primary .irs-to, .slider-primary .irs-single {
  background-color: #00B1E1 !important;
}
.slider-primary .irs-from:after, .slider-primary .irs-to:after, .slider-primary .irs-single:after {
  border-top-color: #00B1E1 !important;
}
.slider-primary .circle .irs-slider {
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.slider-primary .donut .irs-slider {
  background: #e1e4e9 !important;
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 4px solid #00B1E1;
  margin-left: -3px;
}

.slider-success .irs-diapason {
  background-color: #98c865 !important;
}
.slider-success .irs-slider {
  background-color: #8CC152 !important;
}
.slider-success #irs-active-slider, .slider-success .irs-slider:hover {
  background-color: #8CC152 !important;
}
.slider-success .irs-from, .slider-success .irs-to, .slider-success .irs-single {
  background-color: #8CC152 !important;
}
.slider-success .irs-from:after, .slider-success .irs-to:after, .slider-success .irs-single:after {
  border-top-color: #8CC152 !important;
}
.slider-success .circle .irs-slider {
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.slider-success .donut .irs-slider {
  background: #e1e4e9 !important;
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 4px solid #8CC152;
  margin-left: -3px;
}

.slider-info .irs-diapason {
  background-color: #79d9ec !important;
}
.slider-info .irs-slider {
  background-color: #63D3E9 !important;
}
.slider-info #irs-active-slider, .slider-info .irs-slider:hover {
  background-color: #63D3E9 !important;
}
.slider-info .irs-from, .slider-info .irs-to, .slider-info .irs-single {
  background-color: #63D3E9 !important;
}
.slider-info .irs-from:after, .slider-info .irs-to:after, .slider-info .irs-single:after {
  border-top-color: #63D3E9 !important;
}
.slider-info .circle .irs-slider {
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.slider-info .donut .irs-slider {
  background: #e1e4e9 !important;
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 4px solid #63D3E9;
  margin-left: -3px;
}

.slider-warning .irs-diapason {
  background-color: #f7c45a !important;
}
.slider-warning .irs-slider {
  background-color: #F6BB42 !important;
}
.slider-warning #irs-active-slider, .slider-warning .irs-slider:hover {
  background-color: #F6BB42 !important;
}
.slider-warning .irs-from, .slider-warning .irs-to, .slider-warning .irs-single {
  background-color: #F6BB42 !important;
}
.slider-warning .irs-from:after, .slider-warning .irs-to:after, .slider-warning .irs-single:after {
  border-top-color: #F6BB42 !important;
}
.slider-warning .circle .irs-slider {
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.slider-warning .donut .irs-slider {
  background: #e1e4e9 !important;
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 4px solid #F6BB42;
  margin-left: -3px;
}

.slider-danger .irs-diapason {
  background-color: #ec6b56 !important;
}
.slider-danger .irs-slider {
  background-color: #E9573F !important;
}
.slider-danger #irs-active-slider, .slider-danger .irs-slider:hover {
  background-color: #E9573F !important;
}
.slider-danger .irs-from, .slider-danger .irs-to, .slider-danger .irs-single {
  background-color: #E9573F !important;
}
.slider-danger .irs-from:after, .slider-danger .irs-to:after, .slider-danger .irs-single:after {
  border-top-color: #E9573F !important;
}
.slider-danger .circle .irs-slider {
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.slider-danger .donut .irs-slider {
  background: #e1e4e9 !important;
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 4px solid #E9573F;
  margin-left: -3px;
}

.slider-lilac .irs-diapason {
  background-color: #9c6da0 !important;
}
.slider-lilac .irs-slider {
  background-color: #906094 !important;
}
.slider-lilac #irs-active-slider, .slider-lilac .irs-slider:hover {
  background-color: #906094 !important;
}
.slider-lilac .irs-from, .slider-lilac .irs-to, .slider-lilac .irs-single {
  background-color: #906094 !important;
}
.slider-lilac .irs-from:after, .slider-lilac .irs-to:after, .slider-lilac .irs-single:after {
  border-top-color: #906094 !important;
}
.slider-lilac .circle .irs-slider {
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.slider-lilac .donut .irs-slider {
  background: #e1e4e9 !important;
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 4px solid #906094;
  margin-left: -3px;
}

.slider-inverse .irs-diapason {
  background-color: #373737 !important;
}
.slider-inverse .irs-slider {
  background-color: #2A2A2A !important;
}
.slider-inverse #irs-active-slider, .slider-inverse .irs-slider:hover {
  background-color: #2A2A2A !important;
}
.slider-inverse .irs-from, .slider-inverse .irs-to, .slider-inverse .irs-single {
  background-color: #2A2A2A !important;
}
.slider-inverse .irs-from:after, .slider-inverse .irs-to:after, .slider-inverse .irs-single:after {
  border-top-color: #2A2A2A !important;
}
.slider-inverse .circle .irs-slider {
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.slider-inverse .donut .irs-slider {
  background: #e1e4e9 !important;
  top: 21px;
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 4px solid #2A2A2A;
  margin-left: -3px;
}

/* ========================================================================
 * SUMMERNOTE
 * ======================================================================= */
.note-editor {
  border: 1px solid #DDD;
}
.note-editor .note-toolbar {
  border-bottom: 1px solid #DDD;
}
.note-editor .note-editable {
  min-height: 200px;
}

/* ========================================================================
 * WYSIHTML5
 * ======================================================================= */
textarea.rounded + input + .wysihtml5-sandbox {
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
}
textarea.circle + input + .wysihtml5-sandbox {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50%;
  border-radius: 50% !important;
}
textarea + input + .wysihtml5-sandbox {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
  border: 1px solid #DDD !important;
}

.icon-list:before, .icon-th-list:before, .icon-indent-right:before, .icon-indent-left:before, .icon-share:before, .icon-picture:before {
  font-family: 'FontAwesome';
  font-style: normal;
  color: #99999C;
}

.icon-list:before {
  content: "\f03a";
}

.icon-th-list:before {
  content: "\f00b";
}

.icon-indent-right:before {
  content: "\f03b";
}

.icon-indent-left:before {
  content: "\f03c";
}

.icon-share:before {
  content: "\f0c1";
}

.icon-picture:before {
  content: "\f03e";
}

/* ========================================================================
 * DATATABLE
 * ======================================================================= */
.table-default.table.dataTable thead .sorting, .table-default.table.dataTable thead .sorting_asc, .table-default.table.dataTable thead .sorting_desc, .table-default.table.dataTable thead .sorting_asc_disabled, .table-default.table.dataTable thead .sorting_desc_disabled {
  background: #EEE !important;
}

table.dataTable thead tr th, table.dataTable tfoot tr th {
  padding-left: 10px;
  padding-right: 10px;
}
table.dataTable tbody tr td {
  padding-left: 10px;
  padding-right: 10px;
}
table.dataTable tbody tr td.sorting_1 {
  background: #EEEEEE;
  border-bottom: 1px solid #DDD;
}

table.has-columns-hidden > tbody > tr > td > span.responsiveExpander {
  vertical-align: middle;
}
table.has-columns-hidden > tbody > tr > td > span.responsiveExpander:before {
  font-family: "fontawesome";
  content: "\f0fe";
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
}

table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander {
  vertical-align: middle;
}
table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander:before {
  font-family: "fontawesome";
  content: "\f146";
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
}

div.dataTables_filter input {
  margin-left: 10px;
}

div.dataTables_length select {
  margin-right: 10px;
}

.dataTable thead tr th:first-child {
  min-width: 150px;
}

table.dataTable th.dataTables_empty, td.dataTables_empty {
  padding: 30px !important;
  text-align: center;
}
table.dataTable th.dataTables_empty:before, td.dataTables_empty:before {
  font-family: "fontawesome";
  content: "\f119";
  display: block;
  font-size: 110px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  div.dataTables_length label {
    width: 93%;
  }
  div.dataTables_length select {
    display: inline-block;
  }

  div.dataTables_filter label {
    line-height: 30px;
  }
  div.dataTables_filter input {
    display: inline-block;
    float: right;
    width: 13em !important;
    margin-right: 0px;
  }

  div.dataTables_info {
    text-align: center;
    margin-bottom: 10px;
  }

  div.dataTables_paginate {
    float: none !important;
    text-align: center;
  }
}
@media (max-width: 480px) {
  table.dataTable thead tr th:first-child {
    -moz-border-radius: 3px 3px 0px 0px !important;
    -webkit-border-radius: 3px;
    border-radius: 3px 3px 0px 0px !important;
  }
  table.dataTable tfoot tr th:first-child {
    -moz-border-radius: 0px 0px 3px 3px !important;
    -webkit-border-radius: 0px;
    border-radius: 0px 0px 3px 3px !important;
  }

  .dataTables_wrapper .col-xs-6 {
    width: 100%;
  }

  div.dataTables_length label {
    display: none;
  }
}
@media (max-width: 360px) {
  .dataTables_paginate .pagination .previous {
    display: none;
  }
  .dataTables_paginate .pagination > li:nth-child(2) a {
    -moz-border-radius: 3px 0px 0px 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px 0px 0px 3px;
  }

  div.dataTables_filter input {
    width: 19em;
  }
}
@media (max-width: 320px) {
  div.dataTables_filter input {
    width: 16em !important;
  }
}
/* ========================================================================
 * FONT AWESOME
 * ======================================================================= */
.fontawesome-icon-list .fa-hover a {
  display: block;
  line-height: 32px;
  height: 32px;
  color: #636E7B;
  text-decoration: none;
  padding-left: 10px;
}
.fontawesome-icon-list .fa-hover a:hover {
  background-color: #EEEEEE;
}
.fontawesome-icon-list .fa-hover a .fa {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}

/* ========================================================================
 * GRITTER NOTIFICATION
 * ======================================================================= */
.gritter-item-wrapper, .gritter-item, .gritter-top, .gritter-bottom {
  background: fadeout(#2A2A2A, 35% !important);
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
}

#gritter-notice-wrapper {
  top: 70px !important;
  right: 20px !important;
  z-index: 1030 !important;
}

.gritter-close {
  left: auto !important;
  right: 3px !important;
}

.gritter-title {
  font-size: 18px;
  font-weight: 300;
  text-shadow: none;
}

.gritter-item {
  font-family: 'Open Sans';
}

.gritter-top {
  background: url(../images/gritter.png) no-repeat left -35px;
  height: 0;
}

.gritter-bottom {
  height: 0;
}

.gritter-image {
  margin-top: 10px;
}

.gritter-with-image {
  margin-top: 10px;
}

.gritter-item-wrapper, .gritter-item, .gritter-top, .gritter-bottom {
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0;
  border-radius: 0 !important;
}

/* ========================================================================
 * NICESCROLL
 * ======================================================================= */
.nicescroll-rails#ascrail2000-hr {
  display: none !important;
}
.nicescroll-rails div {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}

/* ========================================================================
 * FUEL UX
 * ======================================================================= */
.fuelux .repeater-thumbnail {
  width: 109px;
}
.fuelux .repeater-thumbnail img {
  margin-top: 0px;
}
.fuelux .repeater-thumbnail-cont.align-left .repeater-thumbnail {
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.fuelux .repeater, .fuelux .repeater-header {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.fuelux .repeater-list-header td.sortable:last-child {
  text-align: center;
}
.fuelux .repeater-list-header td.sortable:hover {
  background: transparent;
}
.fuelux .repeater-list-items td:last-child {
  text-align: center;
}
.fuelux .repeater-list table thead > tr > th {
  border-bottom: none;
}
.fuelux .repeater table thead tr th:nth-child(1), .fuelux .repeater table thead tr th:nth-child(3), .fuelux .repeater table thead tr th:nth-child(6) {
  text-align: center;
}
.fuelux .repeater table thead tr th:nth-child(2) {
  width: 200px !important;
}
.fuelux .repeater table thead tr th:nth-child(4) {
  text-align: right;
}
.fuelux .repeater table thead tr th:last-child {
  text-align: center;
}
.fuelux .repeater table tbody tr td {
  vertical-align: middle;
  font-size: 12px;
}
.fuelux .repeater table tbody tr td:nth-child(1), .fuelux .repeater table tbody tr td:nth-child(3), .fuelux .repeater table tbody tr td:nth-child(6) {
  text-align: center;
}
.fuelux .repeater table tbody tr td:nth-child(4) {
  text-align: right;
}
.fuelux .repeater table tbody tr td:last-child {
  text-align: center;
}

.repeater-header-right .repeater-filters > button {
  width: 150px !important;
  text-align: left !important;
}

/* ========================================================================
 * CODE PRETIFFY
 * ======================================================================= */
ol.linenums {
  padding: 0px;
}

/* ========================================================================
 * BLOCKUI
 * ======================================================================= */
.blockUI.blockOverlay {
  background-color: white !important;
}

.blockMsg h1 {
  font-size: 15px;
  margin: 10px 0px;
}

/* ========================================================================
 * CUBEPORTFOLIO
 * ======================================================================= */
.cbp {
  margin-bottom: 20px;
}

.cbp-panel {
  width: 100%;
}

.cbp-l-filters-list .cbp-filter-item {
  margin-top: 0;
}

.cbp-l-filters-list-first, .cbp-l-filters-list-last {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.cbp-l-loadMore-button .cbp-l-loadMore-link, .cbp-l-loadMore-button .cbp-l-loadMore-button-link {
  margin-bottom: 20px;
}

.cbp-l-filters-button {
  margin-bottom: 5px;
}
.cbp-l-filters-button .cbp-filter-item {
  margin-top: 0;
  margin-bottom: 15px;
}

#grid-container {
  margin-top: -20px;
  margin-bottom: 50px;
}

.cbp-l-project-details-list {
  list-style: none;
  padding: 0;
}

.cbp-l-project-related-wrap {
  padding: 0;
}
.cbp-l-project-related-wrap a {
  text-decoration: none;
}

.cbp-l-project-details-visit {
  text-decoration: none !important;
}

.cbp-l-filters-dropdown {
  margin-bottom: 0;
}

.cbp-l-caption-buttonLeft:hover, .cbp-l-caption-buttonRight:hover {
  color: white !important;
}

/* ========================================================================
 * PEITY CHARTS
 * ======================================================================= */
.peity {
  width: inherit;
}

/* ========================================================================
 * EASY PIE CHART
 * ======================================================================= */
.easy-pie-chart {
  text-align: center;
  margin: 10px;
  display: inline-block;
}
.easy-pie-chart.easy-pie-xs .percentage {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 9px;
}
.easy-pie-chart.easy-pie-xs .percentage img {
  margin: 0 auto;
  padding: 15px;
}
.easy-pie-chart.easy-pie-xs .percentage i {
  font-size: 13px;
}
.easy-pie-chart.easy-pie-xs .title {
  margin-top: 5px;
}
.easy-pie-chart.easy-pie-sm .percentage {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 15px;
}
.easy-pie-chart.easy-pie-sm .percentage img {
  margin: 0 auto;
  padding: 25px;
}
.easy-pie-chart.easy-pie-sm .percentage i {
  font-size: 23px;
}
.easy-pie-chart.easy-pie-sm .title {
  margin-top: 10px;
}
.easy-pie-chart.easy-pie-md .percentage {
  width: 150px;
  height: 150px;
  line-height: 150px;
  font-size: 20px;
}
.easy-pie-chart.easy-pie-md .percentage img {
  margin: 0 auto;
  padding: 35px;
}
.easy-pie-chart.easy-pie-md .percentage i {
  font-size: 33px;
}
.easy-pie-chart.easy-pie-lg .percentage {
  width: 200px;
  height: 200px;
  line-height: 200px;
  font-size: 25px;
}
.easy-pie-chart.easy-pie-lg .percentage img {
  margin: 0 auto;
  padding: 45px;
}
.easy-pie-chart.easy-pie-lg .percentage i {
  font-size: 43px;
}
.easy-pie-chart.easy-pie-xl .percentage {
  width: 250px;
  height: 250px;
  line-height: 250px;
  font-size: 30px;
}
.easy-pie-chart.easy-pie-xl .percentage img {
  margin: 0 auto;
  padding: 55px;
}
.easy-pie-chart.easy-pie-xl .percentage i {
  font-size: 53px;
}
.easy-pie-chart .percentage {
  color: #333;
  font-weight: 100;
  font-size: 1em;
  margin: 0 auto;
  position: relative;
  text-align: center;
}
.easy-pie-chart .percentage canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.easy-pie-chart .title {
  display: block;
  text-align: center;
  color: #333;
  font-weight: 300;
  font-size: 16px;
  margin-top: 20px;
  text-decoration: none;
}
.easy-pie-chart .title.up {
  margin-top: 0;
  margin-bottom: 10px;
}

/* ========================================================================
 * BOOTSTRAP COLOR PICKER
 * ======================================================================= */
.colorpicker.dropdown-menu {
  padding: 4px;
}

.colorpicker-2x .colorpicker-saturation {
  width: 200px;
  height: 200px;
}
.colorpicker-2x .colorpicker-hue, .colorpicker-2x .colorpicker-alpha {
  width: 30px;
  height: 200px;
}

.colorpicker-2x .colorpicker-color,
.colorpicker-2x .colorpicker-color div {
  height: 30px;
}

/* ========================================================================
 * BOOTSTRAP DATE RANGE PICKER
 * ======================================================================= */
.daterangepicker {
  padding: 10px;
}
.daterangepicker .input-mini {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: none !important;
}
.daterangepicker .input-mini:link, .daterangepicker .input-mini:focus, .daterangepicker .input-mini:active, .daterangepicker .input-mini:visited {
  border: 1px solid #8CC152;
}
.daterangepicker .input-mini.active {
  border: 1px solid #8CC152;
}
.daterangepicker .calendar-time {
  margin: 15px auto;
  padding-left: 0;
}
.daterangepicker .calendar-time i {
  font-size: 25px;
  margin-top: -5px;
  left: 0;
}
.daterangepicker .calendar-table {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.daterangepicker th:hover {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #8CC152;
  border-color: #8CC152;
  color: #fff;
}
.daterangepicker td.start-date, .daterangepicker td.end-date, .daterangepicker td.start-date.end-date {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.daterangepicker td:hover {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.daterangepicker .daterangepicker_input {
  margin-bottom: 15px;
}
.daterangepicker .ranges ul {
  margin-bottom: 15px;
}
.daterangepicker .ranges .range_inputs .btn:first-child {
  margin-right: 5px;
}
.daterangepicker .ranges li {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: darkgray;
}
.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background: #8CC152;
  border: 1px solid #8CC152;
}

/* ========================================================================
 * GRID STACK
 * ======================================================================= */
.panel.grid-stack-item-content {
  background-color: #FBFBFB;
}

/* ========================================================================
 * SELECT2
 * ======================================================================= */
.select2-container {
  width: 100% !important;
}

.select2-result-repository {
  padding-top: 4px;
  padding-bottom: 3px;
}

.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 10px;
}

.select2-result-repository__avatar img {
  width: 100%;
  height: auto;
  border-radius: 2px;
}

.select2-result-repository__meta {
  margin-left: 70px;
}

.select2-result-repository__title {
  color: black;
  font-weight: bold;
  word-wrap: break-word;
  line-height: 1.1;
  margin-bottom: 4px;
}

.select2-result-repository__forks, .select2-result-repository__stargazers {
  margin-right: 1em;
}

.select2-result-repository__forks, .select2-result-repository__stargazers, .select2-result-repository__watchers {
  display: inline-block;
  color: #aaa;
  font-size: 11px;
}

.select2-result-repository__description {
  font-size: 13px;
  color: #777;
  margin-top: 4px;
}

.select2-results__option--highlighted .select2-result-repository__title {
  color: white;
}

.select2-results__option--highlighted .select2-result-repository__forks, .select2-results__option--highlighted .select2-result-repository__stargazers, .select2-results__option--highlighted .select2-result-repository__description, .select2-results__option--highlighted .select2-result-repository__watchers {
  color: #c6dcef;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #DDD;
  border-radius: 0;
  color: #a2a2a2 !important;
}

.select2-container .select2-selection--single {
  height: 35px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 31px;
  padding-left: 11px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-left: -10px;
  margin-top: 2px;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #DDD;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

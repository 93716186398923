/*line css here disabled for support design blankon */
/*table.has-columns-hidden > tbody > tr > td > span.responsiveExpander {*/
    /*background: url('../images/plus.png') no-repeat 5px center;*/
    /*padding-left: 32px;*/
    /*cursor: pointer;*/
/*}*/

/*table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander {*/
    /*background: url('../images/minus.png') no-repeat 5px center;*/
/*}*/

table.has-columns-hidden > tbody > tr.row-detail > td {
    background: #eee;
}

table.has-columns-hidden > tbody > tr.row-detail > td > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

table.has-columns-hidden > tbody > tr.row-detail > td > ul > li > span.columnTitle {
    font-weight: bold;
}
